var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontFamily } from './Toolbar';
export var SelectFontFamily = function (props) {
    var t = useTranslation('core').t;
    return (_jsx(Select, __assign({ value: props.value, onChange: props.onChange, className: 'select-field', sx: { fontFamily: props.value } }, { children: Object.values(FontFamily).map(function (fontFamily) { return (_jsx(MenuItem, __assign({ value: fontFamily, sx: { fontFamily: fontFamily } }, { children: t("richTextEditor.toolbar.fontFamilyLabels.".concat(fontFamily)) }), fontFamily)); }) })));
};

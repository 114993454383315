var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Tab, Tabs as MuiTabs } from '@mui/material';
import { StringUtils } from '../../utils';
import { Label } from '../Label';
export var Tabs = function (props) {
    var handleChange = function (event, value) {
        props.onChange(value);
    };
    var handleTabClick = function (tab) {
        if (props.onTabClick) {
            props.onTabClick(tab);
        }
    };
    return (_jsx(MuiTabs, __assign({ className: StringUtils.combineStrings(['localina-tabs', props.className]), value: props.active, onChange: handleChange, variant: "scrollable", scrollButtons: false, orientation: props.orientation }, { children: props.tabs.map(function (tab, index) { return (_jsx(Tab, { className: StringUtils.combineStrings(['localina-tab', tab.className]), label: _jsxs(_Fragment, { children: [_jsx(Label, { type: "text", value: tab.label }), Boolean(tab.badgeProps) && _jsx(Badge, __assign({ className: "localina-badge" }, tab.badgeProps))] }), value: tab.value, onClick: function () {
                handleTabClick(tab);
            } }, index)); }) })));
};

import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { IntegrationItem } from '../containers/Integrations/Integrations';
import { Path } from '../enums';

interface IPathParams {
    restaurantId?: string;
    guestId?: string;
    messageId?: string;
    reservationId?: string;
    userId?: string;
    tablePlanId?: string;
    integrationId?: IntegrationItem;
}

interface IQueryParams {
    [key: string]: string;
}

const generateUrl = (path: string, params: IPathParams, search?: IQueryParams) => {
    let url = path
        .replace(':restaurantId', params.restaurantId ?? '')
        .replace(':guestId', params.guestId ?? '')
        .replace(':messageId', params.messageId ?? '')
        .replace(':reservationId', params.reservationId ?? '')
        .replace(':userId', params.userId ?? '')
        .replace(':tablePlanId', params.tablePlanId ?? '')
        .replace(':integrationId', params.integrationId ?? '');

    if (search) {
        const queryParams = new URLSearchParams();
        Object.keys(search).forEach((key) => {
            queryParams.append(key, search[key]);
        });
        url = url.concat(`?${queryParams.toString()}`);
    }

    return url;
};

const useIsPublicPath = () => {
    const location = useLocation();

    return useMemo(() => {
        const publicPaths: string[] = [
            Path.AUTH,
            Path.LOGIN,
            Path.REGISTER,
            Path.RESET_PASSWORD,
            Path.VERIFY_EMAIL,
            Path.REVIEW_PENDING_RESERVATIONS,
        ];
        const matchedPath = publicPaths.find((path) => matchPath(path, location.pathname));
        return [Boolean(matchedPath), matchedPath || null] as const;
    }, [location.pathname]);
};

export const PathUtils = {
    generateUrl,
};

export type { IPathParams };

export { useIsPublicPath };

import {
    Button,
    ColorPicker,
    FabButton,
    Label,
    RestaurantPublicMenu,
    SelectFontFamily,
    Spinner,
    TextField,
    useConfirmContext,
} from '@localina/core';
import { SelectChangeEvent } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Color } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useRestaurantMenus, useUpdateMenuConfigurationMutation } from '../../../api/queries/restaurantMenu';
import { useRestaurant } from '../../../api/queries/restaurants';
import { SaveIcon } from '@localina/icons';

type TMenuConfigurationForm = {
    tabTextFontFamily?: string;
    backgroundColor?: Color;
    defaultTabTextColor?: Color;
    activeTabTextColor?: Color;
    cssUrl?: string;
};

const defaultMenuConfiguration = {
    backgroundColor: '#FFFFFF',
    defaultTabTextColor: '#949494',
    activeTabTextColor: '#1976d2',
    tabTextFontFamily: 'Roboto',
    cssUrl: '',
};
type TMenuAppearanceProps = {
    setContentNotSaved: Dispatch<SetStateAction<boolean>>;
};
export const MenuAppearance = (props: TMenuAppearanceProps) => {
    const { t } = useTranslation();
    const { snackbar } = useConfirmContext();
    const restaurantQuery = useRestaurant();
    const restaurantMenusQuery = useRestaurantMenus();

    const updateMenuConfigurationMutation = useUpdateMenuConfigurationMutation();

    const [menuConfiguration, setMenuConfiguration] = React.useState<TMenuConfigurationForm>(defaultMenuConfiguration);
    const [initialMenuConfiguration, setInitialMenuConfiguration] =
        React.useState<TMenuConfigurationForm>(defaultMenuConfiguration);

    const changeMenuConfiguration =
        (field: keyof TMenuConfigurationForm) => (value: TMenuConfigurationForm[typeof field]) => {
            setMenuConfiguration((prev) => ({ ...prev, [field]: value }));
        };

    const onTabFontFamilyChange = (event: SelectChangeEvent) => {
        changeMenuConfiguration('tabTextFontFamily')(event.target.value);
    };

    const [untestedCssUrl, setUntestedCssUrl] = useState<string | undefined>(undefined);

    const testCssUrl = () => {
        changeMenuConfiguration('cssUrl')(untestedCssUrl);
    };

    const handleSave = () => {
        updateMenuConfigurationMutation.mutate(
            {
                tabTextFontFamily: menuConfiguration.tabTextFontFamily || null,
                backgroundColor: menuConfiguration.backgroundColor ? String(menuConfiguration.backgroundColor) : null,
                defaultTabTextColor: menuConfiguration.defaultTabTextColor
                    ? String(menuConfiguration.defaultTabTextColor)
                    : null,
                activeTabTextColor: menuConfiguration.activeTabTextColor
                    ? String(menuConfiguration.activeTabTextColor)
                    : null,
                cssUrl: menuConfiguration.cssUrl || null,
            },
            {
                onSuccess: () => {
                    snackbar({
                        msg: t('integrations.items.menu.infoMessages.savedMenuSettingsSuccess'),
                        severity: 'success',
                    });
                },
                onError: () => {
                    snackbar({ msg: t('integrations.items.menu.infoMessages.errorMessage'), severity: 'error' });
                },
            },
        );
    };

    useEffect(() => {
        const isContentChanged = () =>
            initialMenuConfiguration.tabTextFontFamily !== menuConfiguration.tabTextFontFamily ||
            initialMenuConfiguration.backgroundColor !== menuConfiguration.backgroundColor ||
            initialMenuConfiguration.defaultTabTextColor !== menuConfiguration.defaultTabTextColor ||
            initialMenuConfiguration.activeTabTextColor !== menuConfiguration.activeTabTextColor ||
            initialMenuConfiguration.cssUrl !== menuConfiguration.cssUrl;

        props.setContentNotSaved(isContentChanged());
    }, [
        menuConfiguration.tabTextFontFamily,
        menuConfiguration.backgroundColor,
        menuConfiguration.defaultTabTextColor,
        menuConfiguration.activeTabTextColor,
        menuConfiguration.cssUrl,
        initialMenuConfiguration,
    ]);

    useEffect(() => {
        if (restaurantQuery.data?.configuration.menuConfig) {
            const configuration = {
                tabTextFontFamily:
                    restaurantQuery.data.configuration.menuConfig.tabTextFontFamily ??
                    defaultMenuConfiguration.tabTextFontFamily,
                backgroundColor:
                    restaurantQuery.data.configuration.menuConfig.backgroundColor ??
                    defaultMenuConfiguration.backgroundColor,
                defaultTabTextColor:
                    restaurantQuery.data.configuration.menuConfig.defaultTabTextColor ??
                    defaultMenuConfiguration.defaultTabTextColor,
                activeTabTextColor:
                    restaurantQuery.data.configuration.menuConfig.activeTabTextColor ??
                    defaultMenuConfiguration.activeTabTextColor,
                cssUrl: restaurantQuery.data.configuration.menuConfig.cssUrl ?? defaultMenuConfiguration.cssUrl,
            };
            setMenuConfiguration(configuration);
            setInitialMenuConfiguration(configuration);
        } else {
            setInitialMenuConfiguration(defaultMenuConfiguration);
        }
    }, [restaurantQuery.data?.configuration.menuConfig]);

    return (
        <div className={'appearance-tab-container'}>
            <div className={'public-preview'}>
                <Label type={'text'} value={t('integrations.items.menu.tabs.appearance.labels.previewTitle')} />
                <RestaurantPublicMenu
                    menuConfiguration={{
                        menu: restaurantMenusQuery.data?.pages[0]?.items || [],
                        tabTextFontFamily: menuConfiguration.tabTextFontFamily,
                        backgroundColor: String(menuConfiguration.backgroundColor),
                        defaultTabTextColor: String(menuConfiguration.defaultTabTextColor),
                        activeTabTextColor: String(menuConfiguration.activeTabTextColor),
                        cssUrl: menuConfiguration.cssUrl,
                    }}
                />
            </div>

            <div className={'additional-settings'}>
                <ColorPicker
                    title={t('integrations.items.menu.tabs.appearance.labels.backgroundColorPicker')}
                    onChange={changeMenuConfiguration('backgroundColor')}
                    value={menuConfiguration.backgroundColor}
                />
                <ColorPicker
                    title={t('integrations.items.menu.tabs.appearance.labels.dateTabTextColor')}
                    onChange={changeMenuConfiguration('defaultTabTextColor')}
                    value={menuConfiguration.defaultTabTextColor}
                />
                <ColorPicker
                    title={t('integrations.items.menu.tabs.appearance.labels.activeTabColor')}
                    onChange={changeMenuConfiguration('activeTabTextColor')}
                    value={menuConfiguration.activeTabTextColor}
                />
                <div className={'select-font-family-wrapper'}>
                    <Label
                        type={'label'}
                        value={t('integrations.items.menu.tabs.appearance.labels.tabTextFontFamily')}
                    />
                    <SelectFontFamily value={menuConfiguration.tabTextFontFamily} onChange={onTabFontFamilyChange} />
                </div>
                <div className={'css-url-wrapper'}>
                    <TextField
                        value={untestedCssUrl}
                        onChange={setUntestedCssUrl}
                        name={'cssUrl'}
                        label={t('integrations.items.menu.tabs.appearance.labels.cssUrl')}
                    />
                    <div className={'buttons'}>
                        <Button
                            label={t('integrations.items.menu.tabs.appearance.labels.testCssButton')}
                            onClick={testCssUrl}
                            secondary
                        />
                    </div>
                </div>
                <div className={'action-buttons-wrapper'}>
                    <div className={'buttons'}>
                        <Button label={'save'} onClick={handleSave} />
                    </div>
                </div>
                <FabButton icon={<SaveIcon />} onClick={handleSave} className="save-fab-button" />
            </div>
            {updateMenuConfigurationMutation.isLoading && <Spinner />}
        </div>
    );
};

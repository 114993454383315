import {
    ConfirmDialogProvider,
    FormErrorMessagesProvider,
    MAINTENANCE_MODE,
    MaintenanceComponent,
} from '@localina/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { i18n } from 'i18next';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { I18nextProvider } from 'react-i18next';
import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { AppContainer } from '../components';
import { CustomDndPreview } from '../components/CustomDndPreview';
import MenuIntegrationPage from '../components/IntegrationViews/MenuIntegration/MenuIntegrationPage';
import { UserFeature } from '../components/UserFeature';
import { ReservationsContextProvider } from '../contexts/ReservationsContext';
import { Path } from '../enums';
import { getBackend } from '../utils/DndUtils';
import Auth from './Auth/Auth';
import Availability from './Availability/Availability';
import Dashboard from './Dashboard/Dashboard';
import Guest from './Guest/Guest';
import GuestReservation from './GuestReservation/GuestReservation';
import Guests from './Guests/Guests';
import Integrations from './Integrations/Integrations';
import Login from './Login/Login';
import Message from './Message/Message';
import Messages from './Messages/Messages';
import Register from './Register/Register';
import Reports from './Reporting/Reports';
import Reservations from './Reservations/Reservations';
import ResetPassword from './ResetPassword/ResetPassword';
import RestaurantRoutes from './RestaurantRoutes/RestaurantRoutes';
import Restaurants from './Restaurants/Restaurants';
import ReviewPendingReservation from './ReviewPendingReservation/ReviewPendingReservation';
import Sectors from './Sectors/Sectors';
import StaffTags from './StaffTags/StaffTags';
import SupportAccounts from './Support/Accounts/SupportAccounts';
import SalesStatistics from './Support/Statistics/SalesStatistics';
import SupportUser from './Support/User/SupportUser';
import SupportUsers from './Support/Users/SupportUsers';
import TablePlanAssignReservations from './Tableplans/TablePlanAssignReservations';
import TablePlanEditor from './Tableplans/TablePlanEditor';
import TablePlans from './Tableplans/TablePlans';
import Users from './Users/Users';
import VerifyEmail from './VerifyEmail/VerifyEmail';

interface IProps {
    i18n: i18n;
}

const touchOptions = {
    delayTouchStart: 200,
};

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
});

class App extends React.Component<IProps> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        /* eslint-disable no-console */
        console.error('MyLocalina Service error:', error, errorInfo);
    }

    render() {
        const backend = getBackend();
        if (MAINTENANCE_MODE) {
            return (
                <I18nextProvider i18n={this.props.i18n}>
                    <MaintenanceComponent />
                </I18nextProvider>
            );
        }
        return (
            <I18nextProvider i18n={this.props.i18n}>
                <QueryClientProvider client={queryClient}>
                    <FormErrorMessagesProvider />
                    <Router>
                        <DndProvider options={backend === TouchBackend ? touchOptions : undefined} backend={backend}>
                            {backend === TouchBackend && <CustomDndPreview />}
                            <ConfirmDialogProvider>
                                <ReservationsContextProvider>
                                    <AppContainer>
                                        <Routes>
                                            <Route path={Path.AUTH} element={<Auth />} />
                                            <Route path={Path.LOGIN} element={<Login />} />
                                            <Route path={Path.REGISTER} element={<Register />} />
                                            <Route path={Path.VERIFY_EMAIL} element={<VerifyEmail />} />
                                            <Route path={Path.RESET_PASSWORD} element={<ResetPassword />} />
                                            <Route
                                                path={Path.REVIEW_PENDING_RESERVATIONS}
                                                element={<ReviewPendingReservation />}
                                            />

                                            <Route path={Path.ACCOUNT_RESTAURANTS} element={<Restaurants />} />
                                            <Route
                                                path={Path.ACCOUNT_USERS}
                                                element={
                                                    <UserFeature filter="users">
                                                        <Users />
                                                    </UserFeature>
                                                }
                                            />
                                            <Route path={Path.SUPPORT_ACCOUNTS} element={<SupportAccounts />} />
                                            <Route path={Path.SUPPORT_STATISTICS} element={<SalesStatistics />} />
                                            <Route path={Path.SUPPORT_USERS} element={<SupportUsers />} />
                                            <Route path={Path.SUPPORT_USER} element={<SupportUser />} />

                                            <Route element={<RestaurantRoutes />}>
                                                <Route
                                                    path={Path.RESTAURANT_DASHBOARD}
                                                    element={
                                                        <UserFeature filter="base">
                                                            <Dashboard />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_RESERVATIONS}
                                                    element={
                                                        <UserFeature filter="base">
                                                            <Reservations />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_GUESTS}
                                                    element={
                                                        <UserFeature filter="base">
                                                            <Guests />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_GUEST}
                                                    element={
                                                        <UserFeature filter="base">
                                                            <Guest />
                                                        </UserFeature>
                                                    }
                                                >
                                                    <Route
                                                        path={Path.RESTAURANT_GUEST_RESERVATION}
                                                        element={<GuestReservation />}
                                                    />
                                                </Route>
                                                <Route
                                                    path={Path.RESTAURANT_TABLE_PLAN_ASSIGN_RESERVATIONS}
                                                    element={
                                                        <UserFeature filter="tablePlans">
                                                            <TablePlanAssignReservations />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_AREAS}
                                                    element={
                                                        <UserFeature filter={['areas', 'settings']}>
                                                            <Sectors />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_AVAILABILITY}
                                                    element={
                                                        <UserFeature filter={['base', 'settings']}>
                                                            <Availability />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_TABLE_PLAN}
                                                    element={
                                                        <UserFeature filter={['tablePlansEdit', 'settings']}>
                                                            <TablePlans />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_TABLE_PLAN_DETAILS}
                                                    element={
                                                        <UserFeature filter={['tablePlansEdit', 'settings']}>
                                                            <TablePlanEditor />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_INTEGRATION_MENU}
                                                    element={
                                                        <UserFeature filter={['settings', 'menu']}>
                                                            <MenuIntegrationPage />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_INTEGRATIONS}
                                                    element={
                                                        <UserFeature filter={['settings']}>
                                                            <Integrations />
                                                        </UserFeature>
                                                    }
                                                >
                                                    <Route path={Path.RESTAURANT_SETTINGS_INTEGRATION} />
                                                </Route>
                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_STAFF}
                                                    element={
                                                        <UserFeature filter={['staffTagsEdit', 'settings']}>
                                                            <StaffTags />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_MESSAGES}
                                                    element={
                                                        <UserFeature filter={['settings']}>
                                                            <Messages />
                                                        </UserFeature>
                                                    }
                                                />
                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_MESSAGE}
                                                    element={
                                                        <UserFeature filter={['settings']}>
                                                            <Message />
                                                        </UserFeature>
                                                    }
                                                />

                                                <Route
                                                    path={Path.RESTAURANT_SETTINGS_REPORTING}
                                                    element={
                                                        <UserFeature filter="reporting">
                                                            <Reports />
                                                        </UserFeature>
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path={'*'}
                                                element={<Navigate to={Path.RESTAURANT_DASHBOARD} replace />}
                                            />
                                        </Routes>
                                    </AppContainer>
                                </ReservationsContextProvider>
                            </ConfirmDialogProvider>
                        </DndProvider>
                    </Router>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </I18nextProvider>
        );
    }
}

export default App;

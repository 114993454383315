import React from 'react';
import { Button, Label, TextField } from '@localina/core';
import { Divider, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { usePDF } from 'react-to-pdf';
import { useRestaurantId } from '../../../utils/RestaurantUtils';

const MenuIntegrations = () => {
    const { t } = useTranslation();
    const restaurantId = useRestaurantId();
    const [showHtmlIntegrationTooltip, setShowHtmlIntegrationTooltip] = React.useState(false);
    const [showQrIntegrationTooltip, setShowQrIntegrationTooltip] = React.useState(false);
    const handleCopyIntegrationScript = async () => {
        await navigator.clipboard.writeText(integrationScript);
        setShowHtmlIntegrationTooltip(true);
        setTimeout(() => {
            setShowHtmlIntegrationTooltip(false);
        }, 3000);
    };

    const menuLink = `${window.location.origin}/widget/?id=${restaurantId}&menu`;
    const integrationScript =
        `<iframe \n` +
        `loading="lazy" id="inline-frame-restaurant-menu" name="Inline Frame Restaurant Menu" width="100%" height="500px" style="border: none; overflow: auto;" ` +
        ` src=${menuLink}>\n` +
        '</iframe>';

    const handleCopyLink = async () => {
        await navigator.clipboard.writeText(menuLink);
        setShowQrIntegrationTooltip(true);
        setTimeout(() => {
            setShowQrIntegrationTooltip(false);
        }, 3000);
    };

    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
    const handleDownloadQRCode = () => {
        toPDF();
    };

    return (
        <div className={'menu-integrations-tab-container'}>
            <div className="group">
                <Label type={'text'} value={'Website integration'} />
                <TextField
                    label={t('integrations.items.menu.tabs.integrations.htmlIntegrationLabel')}
                    name="link"
                    disabled
                    multiline
                    rows={7}
                    value={integrationScript}
                />
                <div className="buttons">
                    <Tooltip
                        open={showHtmlIntegrationTooltip}
                        title={<Label type="info" value={t('integrations.items.menu.tabs.integrations.feedback')} />}
                        placement="left"
                    >
                        <Button
                            label={t('integrations.items.menu.tabs.integrations.buttons.copyHtml')}
                            secondary
                            onClick={handleCopyIntegrationScript}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="divider-vertical">
                <Divider variant="fullWidth" orientation={'vertical'} />
            </div>
            <div className="divider-horizontal">
                <Divider variant="fullWidth" orientation={'horizontal'} />
            </div>
            <div className="group">
                <Label type={'text'} value={'QR Code'} />
                <div className={'qrcode-container'}>
                    <QrCodeComponent link={menuLink} />
                </div>
                <div className={'qrcode-container-to-download'} style={{ position: 'absolute', left: '-500%' }}>
                    <QrCodeComponent ref={targetRef} link={menuLink} />
                </div>
                <div className="buttons">
                    <Tooltip
                        open={showQrIntegrationTooltip}
                        title={<Label type="info" value={t('integrations.items.qrcode.feedback')} />}
                    >
                        <Button
                            label={t('integrations.items.menu.tabs.integrations.buttons.copyLink')}
                            secondary
                            onClick={handleCopyLink}
                        />
                    </Tooltip>
                    <Button
                        label={t('integrations.items.menu.tabs.integrations.buttons.downloadQrCode')}
                        secondary
                        onClick={handleDownloadQRCode}
                    />
                </div>
            </div>
        </div>
    );
};

interface IQrCodeComponentProps {
    link: string;
}

const QrCodeComponent = React.forwardRef<HTMLDivElement, IQrCodeComponentProps>((props, ref) => {
    const { t } = useTranslation();
    return (
        <div className={'qrcode-wrapper'} id="qrcode-wrapper" ref={ref}>
            <div className="qrcode">
                <QRCode id="qrcode" level="H" size={300} value={props.link} fgColor={'#003a5c'} />
            </div>

            <div className="qrcode-subtitle">
                <Label type={'text'} value={t('integrations.items.menu.tabs.integrations.qrCodeSubtitle')} />
            </div>
        </div>
    );
});

export default MenuIntegrations;

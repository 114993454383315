import {
    IAccount,
    IAreaTimeSlot,
    IChangeReservationStatus,
    ICreateReservation,
    ICreateRestaurant,
    ICreateWalkinReservation,
    IDailyNote,
    IGuestDetails,
    IGuestInfo,
    IGuestList,
    IGuestSearchFields,
    IImportMetadataResponse,
    IImportResult,
    IMenuConfiguration,
    IMenusList,
    IMessage,
    IMessageList,
    IPlatform,
    IPublicReservationForReview,
    IReportEntry,
    IReservation,
    IReservationList,
    IReservations,
    IRestaurant,
    IRestaurantAvailableOccupancyTimes,
    IRestaurantClosingDay,
    IRestaurants,
    IRestaurantShift,
    IRestaurantStats,
    ISaveAreaRequest,
    ISaveTablePlanRequest,
    ISort,
    IStaffTag,
    ITablePlanDesignRequest,
    IUpdateMenuContentVariables,
    IUpdatePlatforms,
    IUpdateReservation,
    IUpdateRestaurant,
    IUpdateWalkinReservation,
    IUser,
    MarkReservationAsReadRequest,
    Rest,
    TCreateStaffTag,
    TUpdateVirtualAreaRequest,
} from '@localina/core';
import { GenericAbortSignal } from 'axios';
import { ExportFormat, GuestsExportFormat } from '../../enums';
import { ILocalinaServiceApi } from '../../interfaces';
import { ServiceApiConstants } from '../../utils';

export class LocalinaServiceApi implements ILocalinaServiceApi {
    async getAccount(): Promise<IAccount> {
        return Rest.getRequest(ServiceApiConstants.getAccount().toString());
    }

    async getRestaurants(): Promise<IRestaurants> {
        return Rest.getRequest(ServiceApiConstants.getRestaurants().toString());
    }

    async updateRestaurant(restaurantId: string, restaurant: IUpdateRestaurant): Promise<string> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateRestaurant(restaurantId).toString(),
            payload: restaurant,
        });
    }

    async createRestaurant(restaurant: ICreateRestaurant): Promise<IRestaurant> {
        return Rest.postRequest({
            url: ServiceApiConstants.createRestaurant().toString(),
            payload: restaurant,
        });
    }

    async getRestaurantAvailableOccupancyTimes(
        restaurantId: string,
        participants: number,
        reservationDateTime: string,
        shiftId: string,
        areaId: string,
    ): Promise<IRestaurantAvailableOccupancyTimes> {
        return Rest.getRequest(
            ServiceApiConstants.getRestaurantAvailableOccupancyTimes(
                restaurantId,
                participants,
                reservationDateTime,
                shiftId,
                areaId,
            ).toString(),
        );
    }

    async createRestaurantShift(restaurantId: string, shift: IRestaurantShift): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.createRestaurantShift(restaurantId).toString(),
            payload: shift,
        });
    }

    async updateRestaurantShift(restaurantId: string, shift: IRestaurantShift): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateRestaurantShift(restaurantId, shift.id).toString(),
            payload: shift,
        });
    }

    async deleteRestaurantShift(restaurantId: string, shift: IRestaurantShift): Promise<void> {
        return Rest.deleteRequest(ServiceApiConstants.updateRestaurantShift(restaurantId, shift.id).toString());
    }

    async createRestaurantClosingDay(restaurantId: string, closingDay: IRestaurantClosingDay): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.createRestaurantClosingDay(restaurantId).toString(),
            payload: closingDay,
        });
    }

    async updateRestaurantClosingDay(restaurantId: string, closingDay: IRestaurantClosingDay): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateRestaurantClosingDay(restaurantId, closingDay.id).toString(),
            payload: closingDay,
        });
    }

    async deleteRestaurantClosingDay(restaurantId: string, closingDayId: string): Promise<void> {
        return Rest.deleteRequest(
            ServiceApiConstants.updateRestaurantClosingDay(restaurantId, closingDayId).toString(),
        );
    }

    async createRestaurantSpecialOpeningHour(
        restaurantId: string,
        specialOpeningHour: IRestaurantShift,
    ): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.createRestaurantSpecialOpeningHour(restaurantId).toString(),
            payload: specialOpeningHour,
        });
    }

    async updateRestaurantSpecialOpeningHour(
        restaurantId: string,
        specialOpeningHour: IRestaurantShift,
    ): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateRestaurantSpecialOpeningHour(restaurantId, specialOpeningHour.id).toString(),
            payload: specialOpeningHour,
        });
    }

    async deleteRestaurantSpecialOpeningHour(
        restaurantId: string,
        specialOpeningHour: IRestaurantShift,
    ): Promise<void> {
        return Rest.deleteRequest(
            ServiceApiConstants.updateRestaurantSpecialOpeningHour(restaurantId, specialOpeningHour.id).toString(),
        );
    }

    async getActivePlatforms(): Promise<IPlatform[]> {
        return Rest.getRequest(ServiceApiConstants.getActivePlatforms().toString());
    }

    async updateRestaurantPlatforms(restaurantId: string, platforms: IUpdatePlatforms): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateRestaurantPlatforms(restaurantId).toString(),
            payload: platforms,
        });
    }

    async getRestaurantStats(restaurantId: string, dates: string[]): Promise<IRestaurantStats> {
        return Rest.postRequest({
            url: ServiceApiConstants.getRestaurantStats(restaurantId).toString(),
            payload: dates,
        });
    }

    async saveRestaurantLogo(restaurantId: string, logo: File): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.getRestuarantLogo(restaurantId).toString(),
            contentType: 'application/octet-stream',
            payload: logo,
        });
    }

    async deleteRestuarantLogo(restaurantId: string): Promise<void> {
        return Rest.deleteRequest(ServiceApiConstants.getRestuarantLogo(restaurantId).toString());
    }

    async saveRestaurantDocument(restaurantId: string, document: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', document);
        return Rest.postRequest({
            url: ServiceApiConstants.getRestaurantDocument(restaurantId).toString(),
            contentType: 'multipart/form-data',
            payload: formData,
        });
    }

    async getRestaurantDocument(url: string): Promise<File> {
        return Rest.getRequest({
            url,
            responseType: 'blob',
            headers: {
                Authorization: null,
            },
        });
    }

    async getReservations(restaurantId: string, date: string, signal?: GenericAbortSignal): Promise<IReservations> {
        return Rest.getRequest({
            url: ServiceApiConstants.getReservations(restaurantId, date).toString(),
            signal,
        });
    }

    async getReservation(restaurantId: string, reservationId: string): Promise<IReservation> {
        return Rest.getRequest(ServiceApiConstants.getReservation(restaurantId, reservationId).toString());
    }

    async getPublicReservationByToken(restaurantId: string, token: string) {
        return Rest.getRequest<IPublicReservationForReview>(
            ServiceApiConstants.getPublicReservationByToken(restaurantId, token).toString(),
        );
    }

    async createReservation(restaurantId: string, reservation: ICreateReservation): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.createReservation(restaurantId).toString(),
            payload: reservation,
        });
    }

    async createWalkinReservation(restaurantId: string, reservation: ICreateWalkinReservation): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.createWalkinReservation(restaurantId).toString(),
            payload: reservation,
        });
    }

    async updateReservation(
        restaurantId: string,
        reservationId: string,
        reservation: IUpdateReservation,
    ): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.getReservation(restaurantId, reservationId).toString(),
            payload: reservation,
        });
    }

    async updateReservationStatus(
        restaurantId: string,
        reservationId: string,
        status: IChangeReservationStatus,
    ): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.updateReservationStatus(restaurantId, reservationId).toString(),
            payload: status,
        });
    }

    async updateReservationStatusByToken(
        restaurantId: string,
        reservationId: string,
        token: string,
        status: IChangeReservationStatus,
    ): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.updateReservationStatusByToken(restaurantId, reservationId, token).toString(),
            payload: status,
        });
    }

    async updateWalkinReservation(
        restaurantId: string,
        reservationId: string,
        reservation: IUpdateWalkinReservation,
    ): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateWalkinReservation(restaurantId, reservationId).toString(),
            payload: reservation,
        });
    }

    async getUnreadReservations(restaurantId: string): Promise<IReservations> {
        return Rest.getRequest(ServiceApiConstants.getUnreadReservations(restaurantId).toString());
    }

    async markReservationAsRead(restaurantId: string, request: MarkReservationAsReadRequest): Promise<string[]> {
        return Rest.putRequest({
            url: ServiceApiConstants.markReservationAsRead(restaurantId).toString(),
            payload: request,
        });
    }

    async getReservationsImportPreview(restaurantId: string, file: File): Promise<IImportResult> {
        const formData = new FormData();
        formData.append('file', file);
        return Rest.postRequest({
            url: ServiceApiConstants.getReservationsImportPreview(restaurantId).toString(),
            payload: formData,
            contentType: 'multipart/form-data',
        });
    }

    async getValidatedReservationsExport(restaurantId: string, file: File): Promise<File> {
        const formData = new FormData();
        formData.append('file', file);
        return Rest.postRequest({
            url: ServiceApiConstants.getValidatedReservationsExport(restaurantId).toString(),
            payload: formData,
            contentType: 'multipart/form-data',
            responseType: 'arraybuffer',
        });
    }

    async saveReservationsImport(restaurantId: string, file: File): Promise<IImportResult> {
        const formData = new FormData();
        formData.append('file', file);
        return Rest.postRequest({
            url: ServiceApiConstants.getReservationsImport(restaurantId).toString(),
            payload: formData,
            contentType: 'multipart/form-data',
        });
    }

    async getGuestList(
        restaurantId: string,
        page: number,
        limit: number,
        sort?: ISort,
        query?: string,
        field?: IGuestSearchFields,
        signal?: GenericAbortSignal,
    ): Promise<IGuestList> {
        return Rest.getRequest({
            url: ServiceApiConstants.getGuestList(restaurantId, page, limit, sort, query, field).toString(),
            signal,
        });
    }

    async getGuestsExport(restaurantId: string, format: GuestsExportFormat): Promise<File> {
        return Rest.getRequest({
            url: ServiceApiConstants.getGuestsExport(restaurantId, format).toString(),
            responseType: 'blob',
        });
    }

    async getGuestsImportPreview(restaurantId: string, file: File): Promise<IImportResult> {
        const formData = new FormData();
        formData.append('file', file);
        return Rest.postRequest({
            url: ServiceApiConstants.getGuestsImportPreview(restaurantId).toString(),
            payload: formData,
            contentType: 'multipart/form-data',
        });
    }

    async saveGuestsImport(restaurantId: string, file: File): Promise<IImportResult> {
        const formData = new FormData();
        formData.append('file', file);
        return Rest.postRequest({
            url: ServiceApiConstants.getGuestsImport(restaurantId).toString(),
            payload: formData,
            contentType: 'multipart/form-data',
        });
    }

    async getImportMetadata(restaurantId: string): Promise<IImportMetadataResponse> {
        return Rest.getRequest(ServiceApiConstants.getImportMetadata(restaurantId).toString());
    }

    async getGuest(restaurantId: string, guestId: string): Promise<IGuestDetails> {
        return Rest.getRequest(ServiceApiConstants.getGuest(restaurantId, guestId).toString());
    }

    async getGuestReservationList(
        restaurantId: string,
        guestId: string,
        page: number,
        limit: number,
    ): Promise<IReservationList> {
        return Rest.getRequest(
            ServiceApiConstants.getGuestReservationList(restaurantId, guestId, page, limit).toString(),
        );
    }

    async updateGuest(restaurantId: string, guest: IGuestInfo): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.getGuest(restaurantId, guest.id).toString(),
            payload: guest,
        });
    }

    async deleteGuest(restaurantId: string, guest: IGuestInfo): Promise<void> {
        return Rest.deleteRequest(ServiceApiConstants.getGuest(restaurantId, guest.id).toString());
    }

    async getMessageList(restaurantId: string, page: number, limit: number): Promise<IMessageList> {
        return Rest.getRequest(ServiceApiConstants.getMessageList(restaurantId, page, limit).toString());
    }

    async getMessage(restaurantId: string, messageId: string): Promise<IMessage> {
        return Rest.getRequest(ServiceApiConstants.getMessage(restaurantId, messageId).toString());
    }

    async createMessage(restaurantId: string, message: Omit<IMessage, 'id'>): Promise<IMessage> {
        return Rest.postRequest({
            url: ServiceApiConstants.createMessage(restaurantId).toString(),
            payload: message,
        });
    }

    async updateMessage(restaurantId: string, message: IMessage): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.getMessage(restaurantId, message.id).toString(),
            payload: message,
        });
    }

    async deleteMessage(restaurantId: string, message: IMessage): Promise<void> {
        return Rest.deleteRequest(ServiceApiConstants.getMessage(restaurantId, message.id).toString());
    }

    async getUsers(): Promise<IUser[]> {
        return Rest.getRequest(ServiceApiConstants.getUsers().toString());
    }

    async getUser(userId: string): Promise<IUser> {
        return Rest.getRequest(ServiceApiConstants.getUser(userId).toString());
    }

    async createUser(user: IUser): Promise<IUser> {
        return Rest.postRequest({
            url: ServiceApiConstants.getUsers().toString(),
            payload: user,
        });
    }

    async updateUser(user: IUser): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.getUser(user.id).toString(),
            payload: user,
        });
    }

    async deleteUser(user: IUser): Promise<void> {
        return Rest.deleteRequest(ServiceApiConstants.getUser(user.id).toString());
    }

    async saveRestaurantArea(restaurantId: string, areaDetails: ISaveAreaRequest): Promise<string> {
        if (areaDetails.id) {
            return Rest.putRequest({
                url: ServiceApiConstants.editRestaurantArea(restaurantId, areaDetails.id).toString(),
                payload: areaDetails,
            });
        } else {
            return Rest.postRequest({
                url: ServiceApiConstants.saveRestaurantArea(restaurantId).toString(),
                payload: areaDetails,
            });
        }
    }

    async updateRestaurantVirtualArea(restaurantId: string, virtualArea: TUpdateVirtualAreaRequest): Promise<string> {
        return Rest.putRequest({
            url: ServiceApiConstants.editRestaurantVirtualArea(restaurantId, virtualArea.id).toString(),
            payload: virtualArea,
        });
    }

    async deleteRestaurantArea(restaurantId: string, areaId: string): Promise<void> {
        return Rest.deleteRequest(ServiceApiConstants.deleteRestaurantArea(restaurantId, areaId).toString());
    }

    async getAvailableAreaTimeSlots(
        restaurantId: string,
        participants: number,
        reservationDateTime: string,
        areaId: string,
        shiftId: string,
        expectedOccupancyTime?: number,
    ): Promise<{ slots: IAreaTimeSlot[] }> {
        return Rest.getRequest(
            ServiceApiConstants.getAreaTimeSlot(
                restaurantId,
                participants,
                reservationDateTime,
                shiftId,
                areaId,
                expectedOccupancyTime,
            ).toString(),
        );
    }

    async saveTablePlan(restaurantId: string, tablePlan: ISaveTablePlanRequest): Promise<any> {
        const formData = new FormData();
        formData.append('name', tablePlan.name);
        formData.append('id', tablePlan.id);
        formData.append('areaId', tablePlan.areaId);
        if (tablePlan.graphic) {
            formData.append('graphic', tablePlan.graphic);
        }
        return Rest.postRequest({
            url: ServiceApiConstants.saveTablePlan(restaurantId).toString(),
            payload: formData,
            contentType: 'multipart/form-data',
        });
    }

    async updateTablePlan(restaurantId: string, tablePlanId: string, tablePlan: ISaveTablePlanRequest): Promise<void> {
        const formData = new FormData();
        formData.append('name', tablePlan.name);
        formData.append('areaId', tablePlan.areaId);
        if (tablePlan.graphic) {
            formData.append('graphic', tablePlan.graphic);
        }
        return Rest.putRequest({
            url: ServiceApiConstants.updateTablePlan(restaurantId, tablePlanId).toString(),
            payload: formData,
            contentType: 'multipart/form-data',
        });
    }

    async saveTablePlanDesign(
        restaurantId: string,
        tablePlanId: string,
        tablePlanDesignRequest: ITablePlanDesignRequest,
    ): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.saveTablePlanDesign(restaurantId, tablePlanId).toString(),
            payload: tablePlanDesignRequest,
        });
    }

    async deleteTableFromTableEditor(restaurantId: string, tablePlanId: string, tableId: string): Promise<void> {
        return Rest.deleteRequest(
            ServiceApiConstants.deleteTableFromTableEditor(restaurantId, tablePlanId, tableId).toString(),
        );
    }

    async deleteTablePlan(restaurantId: string, tablePlanId: string): Promise<void> {
        return await Rest.deleteRequest(ServiceApiConstants.deleteTablePlan(restaurantId, tablePlanId).toString());
    }

    async getReport(
        restaurantId: string,
        shiftIds: string[],
        areaId: string,
        dateFrom: string,
        dateTo: string,
        creationTime: boolean,
    ): Promise<IReportEntry[]> {
        return Rest.getRequest(
            ServiceApiConstants.getReport(restaurantId, shiftIds, areaId, dateFrom, dateTo, creationTime).toString(),
        );
    }

    async getReportExport(
        restaurantId: string,
        shiftIds: string[],
        areaId: string,
        dateFrom: string,
        dateTo: string,
        creationTime: boolean,
        format: ExportFormat,
    ): Promise<File> {
        return Rest.getRequest({
            url: ServiceApiConstants.getReportExport(
                restaurantId,
                shiftIds,
                areaId,
                dateFrom,
                dateTo,
                creationTime,
                format,
            ).toString(),
            responseType: 'blob',
        });
    }

    async updateReservationTableIds(
        restaurantId: string,
        reservationId: string,
        tableIds: string[],
        mode: 'merge' | 'change',
    ): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateReservationTableIds(restaurantId, reservationId).toString(),
            payload: { tableIds, mode },
        });
    }

    async updateWalkinTableIds(
        restaurantId: string,
        walkinId: string,
        tableIds: string[],
        mode: 'merge' | 'change',
    ): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateWalkinTableIds(restaurantId, walkinId).toString(),
            payload: { tableIds, mode },
        });
    }

    async getDailyNote(restaurantId: string, date: string): Promise<IDailyNote> {
        return Rest.getRequest(ServiceApiConstants.restaurantDailyNote(restaurantId, date).toString());
    }

    async getRestaurantMenu(restaurantId: string, dateFrom: string, dateTo: string): Promise<IMenusList> {
        return Rest.getRequest(ServiceApiConstants.restaurantMenu(restaurantId, dateFrom, dateTo).toString());
    }

    async updateRestaurantMenuConfiguration(restaurantId: string, payload: IMenuConfiguration): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.restaurantMenuConfiguration(restaurantId).toString(),
            payload,
        });
    }

    async updateRestaurantMenuContent(restaurantId: string, payload: IUpdateMenuContentVariables): Promise<void> {
        return Rest.putRequest({
            url: ServiceApiConstants.createAndUpdateRestaurantMenus(restaurantId).toString(),
            payload: payload.menu,
        });
    }

    async saveDailyNote(restaurantId: string, date: string, note: string): Promise<void> {
        return Rest.postRequest({
            url: ServiceApiConstants.restaurantDailyNote(restaurantId, date).toString(),
            payload: { note },
        });
    }

    async createStaffTag(restaurantId: string, staffTag: TCreateStaffTag): Promise<string> {
        return Rest.postRequest({
            url: ServiceApiConstants.createRestaurantStaffTag(restaurantId).toString(),
            payload: staffTag,
        });
    }

    async updateStaffTag(restaurantId: string, staffTag: IStaffTag): Promise<string> {
        return Rest.putRequest({
            url: ServiceApiConstants.updateRestaurantStaffTag(restaurantId, staffTag.id).toString(),
            payload: staffTag,
        });
    }

    async deleteStaffTag(restaurantId: string, staffTagId: string): Promise<void> {
        return Rest.deleteRequest(ServiceApiConstants.deleteRestaurantStaffTag(restaurantId, staffTagId).toString());
    }

    async getDailyExport(restaurantId: string, date: string): Promise<File> {
        return Rest.getRequest({
            url: ServiceApiConstants.getDailyReportExport(restaurantId, date).toString(),
            responseType: 'blob',
        });
    }

    async getExportReservations(restaurantId: string, reservations: string[]): Promise<File> {
        return Rest.postRequest({
            url: ServiceApiConstants.getExportReservations(restaurantId).toString(),
            responseType: 'blob',
            payload: reservations,
        });
    }
}

import { Button, ColorPicker, IRestaurant, Label, StringUtils, TextField, useConfirmContext } from '@localina/core';
import { Divider, Tooltip } from '@mui/material';
import React from 'react';
import { Color } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useUpdateSpecificRestaurantMutation } from '../../../api/queries/restaurants';

interface IProps {
    restaurant: IRestaurant;
    id: string;
}

const WidgetIntegration: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { snackbar } = useConfirmContext();
    const updateRestaurantMutation = useUpdateSpecificRestaurantMutation();

    const [showLinkTooltip, setShowLinkTooltip] = React.useState(false);
    const [showIntegrationTooltip, setShowIntegrationTooltip] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [widgetCssUrl, setWidgetCssUrl] = React.useState(props.restaurant.configuration.widgetCssUrl || '');
    const [isEmailValid, setEmailValid] = React.useState(false);
    const [buttonText, setButtonText] = React.useState<string>(
        props.restaurant.configuration.buttonTheme?.text || t('integrations.items.widget.buttons.default-button-text'),
    );
    const [buttonColor, setButtonColor] = React.useState<Color>(
        props.restaurant.configuration.buttonTheme?.buttonColor || '#821E3E',
    );
    const [textColor, setTextColor] = React.useState<Color>(
        props.restaurant.configuration.buttonTheme?.textColor || '#FFFFFF',
    );
    const [buttonHoverColor, setButtonHoverColor] = React.useState<Color>(
        props.restaurant.configuration.buttonTheme?.buttonHoverColor || '#B17286',
    );
    const [textHoverColor, setTextHoverColor] = React.useState<Color>(
        props.restaurant.configuration.buttonTheme?.textHoverColor || '#FFFFFF',
    );

    const saveWidgetCssUrl = () => {
        updateRestaurantMutation.mutate(
            {
                restaurantId: props.id,
                restaurant: { widgetCssUrl },
            },
            {
                onSuccess: () => {
                    snackbar({
                        msg: t('integrations.items.messages.css-link-successfully-saved'),
                        severity: 'success',
                        autoHideDuration: 2000,
                    });
                },
            },
        );
    };

    React.useEffect(() => {
        setEmailValid(StringUtils.isValidEmail(email));
    }, [email]);

    React.useEffect(() => {
        const addScript = (url: string) => {
            const script = document.createElement('script');

            script.src = url;
            script.type = 'text/javascript';
            script.async = true;

            return script;
        };

        const script1 = addScript('./script/widget.js');
        const script2 = addScript('https://code.jquery.com/jquery-3.7.0.min.js');
        document.head.appendChild(script1);
        document.body.appendChild(script2);

        return () => {
            document.head.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    const linkOrigin =
        process.env.NODE_ENV === 'development'
            ? window.location.origin.replace(':3000', ':3001') // set port 3001 in development
            : window.location.origin;

    const widgetLink = `${linkOrigin}/widget/?id=${props.id}`;

    const integrationScript =
        '<script src="https://code.jquery.com/jquery-3.7.0.min.js" type="text/javascript"></script>\n' +
        '<script src="' +
        linkOrigin +
        '/script/widget.js?reservationButtonColor=' +
        buttonColor.toString() +
        '&reservationButtonHoverColor=' +
        buttonHoverColor.toString() +
        '&reservationButtonTextColor=' +
        textColor.toString() +
        '&reservationButtonTextHoverColor=' +
        textHoverColor.toString() +
        '" type="text/javascript" charset="utf-8"></script>\n' +
        '<script type="text/javascript">jQuery.noConflict();</script>\n' +
        '<a id="localina-button" href="javascript:;" onclick="Localina.startBooking(\'' +
        props.id +
        "', '" +
        linkOrigin +
        '\');">' +
        buttonText +
        '</a>';

    const handleCopyLink = async () => {
        await navigator.clipboard.writeText(widgetLink);
        setShowLinkTooltip(true);
        setTimeout(() => {
            setShowLinkTooltip(false);
        }, 3000);
    };

    const handleCopyIntegrationScript = async () => {
        await navigator.clipboard.writeText(integrationScript);
        setShowIntegrationTooltip(true);
        setTimeout(() => {
            setShowIntegrationTooltip(false);
        }, 3000);
    };

    const handleSendEmail = () => {
        window.open(
            `mailto:${email}?subject=${props.restaurant.info.name}&body=${widgetLink}%0D%0A%0D%0A${encodeURIComponent(
                integrationScript,
            )}`,
        );
    };

    const [isHover, setIsHover] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const buttonStyle = {
        backgroundColor: isHover ? String(buttonHoverColor) : String(buttonColor),
        color: isHover ? String(textHoverColor) : String(textColor),
        width: '212px',
        height: '38px',
    };

    const saveButtonTheme = () => {
        updateRestaurantMutation.mutate(
            {
                restaurantId: props.id,
                restaurant: {
                    buttonTheme: {
                        text: buttonText,
                        buttonColor: buttonColor.toString(),
                        textColor: textColor.toString(),
                        buttonHoverColor: buttonHoverColor.toString(),
                        textHoverColor: textHoverColor.toString(),
                    },
                },
            },
            {
                onSuccess: () => {
                    snackbar({
                        msg: t('integrations.items.messages.successfully-saved'),
                        severity: 'success',
                        autoHideDuration: 2000,
                    });
                },
            },
        );
    };

    const openPopup = () => {
        window.Localina.startBooking(props.id, linkOrigin);
    };

    return (
        <div className="integration widget">
            <Label type={'title'} value={t('integrations.items.subtitles.link')} variant={'h5'} />
            <div className="group">
                <TextField
                    label={t('integrations.items.widget.fields.link')}
                    name="link"
                    disabled={true}
                    multiline={true}
                    minRows={2}
                    value={widgetLink}
                />
                <div className="buttons">
                    <Tooltip
                        open={showLinkTooltip}
                        title={<Label type="info" value={t('integrations.items.widget.feedback')} />}
                        placement="left"
                    >
                        <Button
                            label={t('integrations.items.widget.buttons.copy')}
                            secondary={true}
                            onClick={handleCopyLink}
                        />
                    </Tooltip>
                </div>
            </div>
            <Divider className="title-divider" />
            <Label type={'title'} value={t('integrations.items.subtitles.integration')} variant={'h5'} />
            <div className="group">
                <TextField
                    label={t('integrations.items.widget.fields.button-text')}
                    name={'buttonText'}
                    onChange={setButtonText}
                    value={buttonText}
                    onBlur={saveButtonTheme}
                />
                <div className="color-row">
                    <ColorPicker
                        title={t('integrations.items.widget.fields.button-color')}
                        onChange={setButtonColor}
                        defaultColor={buttonColor}
                        onClose={saveButtonTheme}
                    />
                    <ColorPicker
                        title={t('integrations.items.widget.fields.text-color')}
                        onChange={setTextColor}
                        defaultColor={textColor}
                        onClose={saveButtonTheme}
                    />
                </div>
                <div className="color-row">
                    <ColorPicker
                        title={t('integrations.items.widget.fields.button-hover-color')}
                        onChange={setButtonHoverColor}
                        defaultColor={buttonHoverColor}
                        onClose={saveButtonTheme}
                    />
                    <ColorPicker
                        title={t('integrations.items.widget.fields.text-hover-color')}
                        onChange={setTextHoverColor}
                        defaultColor={textHoverColor}
                        onClose={saveButtonTheme}
                    />
                </div>
                <div className="button-wrapper">
                    <Button
                        style={buttonStyle}
                        label={buttonText}
                        onClick={openPopup}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />
                </div>
            </div>
            <div className="group">
                <TextField
                    label={t('integrations.items.widget.fields.integration')}
                    name="link"
                    disabled={true}
                    multiline={true}
                    minRows={2}
                    value={integrationScript}
                />
                <div className="buttons">
                    <Tooltip
                        open={showIntegrationTooltip}
                        title={<Label type="info" value={t('integrations.items.widget.feedback')} />}
                        placement="left"
                    >
                        <Button
                            label={t('integrations.items.widget.buttons.copy-integration')}
                            secondary
                            onClick={handleCopyIntegrationScript}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="group">
                <TextField
                    label={t('integrations.items.widget.fields.cssUrl')}
                    name="widgetCssUrl"
                    value={widgetCssUrl}
                    onChange={setWidgetCssUrl}
                />
                <div className="buttons">
                    <Button label={t('integrations.items.widget.buttons.save')} secondary onClick={saveWidgetCssUrl} />
                </div>
            </div>
            <Divider className="title-divider" />
            <Label type={'title'} value={t('integrations.items.subtitles.email')} variant={'h5'} />
            <div className="group">
                <TextField
                    label={t('integrations.items.widget.fields.email')}
                    name="email"
                    value={email}
                    onChange={setEmail}
                />
                <div className="buttons">
                    <Button
                        label={t('integrations.items.widget.buttons.send')}
                        secondary
                        disabled={!isEmailValid}
                        onClick={handleSendEmail}
                    />
                </div>
            </div>
        </div>
    );
};

export default WidgetIntegration;

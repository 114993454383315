import { DateTime } from 'luxon';
import { DATE_TIME_FORMAT, SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT, StringUtils, TIME_FORMAT } from '..';
var hasValidDateRange = function (from, to, format) {
    if (format === void 0) { format = SERVER_DATE_FORMAT; }
    var dateFrom = DateTime.fromFormat(from, format);
    var dateTo = DateTime.fromFormat(to, format);
    return dateFrom <= dateTo;
};
var hasValidTimeRange = function (from, to, format) {
    if (format === void 0) { format = TIME_FORMAT; }
    var timeFrom = DateTime.fromFormat(from, format);
    var timeTo = DateTime.fromFormat(to, format);
    return timeFrom.hour < timeTo.hour || (timeFrom.hour === timeTo.hour && timeFrom.minute < timeTo.minute);
};
var hasValidDateTimeRange = function (from, to, format) {
    if (format === void 0) { format = SERVER_DATE_TIME_FORMAT; }
    var dateFrom = DateTime.fromFormat(from, format);
    var dateTo = DateTime.fromFormat(to, format);
    return dateFrom.toSeconds() < dateTo.toSeconds();
};
var isDateInRange = function (datetime, from, to, format) {
    if (format === void 0) { format = SERVER_DATE_FORMAT; }
    var dateFrom = DateTime.fromFormat(from, format).startOf('day');
    var dateTo = DateTime.fromFormat(to, format).endOf('day');
    return isDateTimeInRangeOfDateTimes(datetime, dateFrom, dateTo);
};
var isDateInRangeOfDates = function (date, from, to, format) {
    if (format === void 0) { format = SERVER_DATE_FORMAT; }
    var datetime = DateTime.fromFormat(date, format);
    return isDateTimeInRangeOfDateTimes(datetime, from, to);
};
var isDateTimeInRangeOfDateTimes = function (date, from, to) {
    return date >= from && date <= to;
};
var isTimeInRange = function (datetime, from, to, format) {
    if (format === void 0) { format = TIME_FORMAT; }
    var timeFrom = DateTime.fromFormat(from, format);
    var timeTo = DateTime.fromFormat(to, format);
    var isTimeFromValid = datetime.hour > timeFrom.hour || (datetime.hour === timeFrom.hour && datetime.minute >= timeFrom.minute);
    var isTimeToValid = datetime.hour < timeTo.hour || (datetime.hour === timeTo.hour && datetime.minute < timeTo.minute);
    return isTimeFromValid && isTimeToValid;
};
var getTimeFromDateTime = function (date, time) {
    return DateTime.fromFormat(StringUtils.combineStrings([date, time]), DATE_TIME_FORMAT);
};
var compareStringDateTimes = function (textDateTime1, textDateTime2) {
    var dateTime1 = DateTime.fromFormat(textDateTime1, TIME_FORMAT);
    var dateTime2 = DateTime.fromFormat(textDateTime2, TIME_FORMAT);
    return compareDateTimes(dateTime1, dateTime2);
};
var compareDateTimes = function (dateTime1, dateTime2) {
    if (dateTime1 < dateTime2)
        return -1;
    if (dateTime1 == dateTime2)
        return 0;
    return 1;
};
var isDateInPast = function (dateTimeISO) {
    var dateTime = DateTime.fromISO(dateTimeISO, { zone: 'utc' });
    if (!dateTime.isValid) {
        return false;
    }
    var currentDateTime = DateTime.utc();
    return dateTime.diff(currentDateTime).milliseconds < 0;
};
var areDatesConsecutive = function (dates) {
    return Boolean(dates.every(function (date, index, array) { return index === 0 || date.minus({ day: 1 }).equals(array[index - 1]); }));
};
export var DateTimeUtils = {
    getTimeFromDateTime: getTimeFromDateTime,
    hasValidDateRange: hasValidDateRange,
    hasValidTimeRange: hasValidTimeRange,
    hasValidDateTimeRange: hasValidDateTimeRange,
    isDateInRange: isDateInRange,
    isDateInRangeOfDates: isDateInRangeOfDates,
    isDateTimeInRangeOfDateTimes: isDateTimeInRangeOfDateTimes,
    isTimeInRange: isTimeInRange,
    compareStringDateTimes: compareStringDateTimes,
    compareDateTimes: compareDateTimes,
    isDateInPast: isDateInPast,
    areDatesConsecutive: areDatesConsecutive,
};

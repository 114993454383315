var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DateTime } from 'luxon';
import { DATE_FORMAT, DATE_TIME_FORMAT, SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT, TIME_FORMAT } from '../config';
import { DateTimeUtils } from './DateTimeUtils';
var filterShiftsForDate = function (datetime, shifts) {
    return shifts.filter(function (shift) {
        if (!shift.enabled) {
            return false;
        }
        if (shift.shiftType === 'soh') {
            var dateTimeServerFormat_1 = datetime.toFormat(SERVER_DATE_FORMAT);
            return shift.dates.some(function (date) { return date === dateTimeServerFormat_1; });
        }
        return shift.occurrence.includes(datetime.weekday);
    });
};
var filterClosingDaysForDate = function (datetime, closingDays) {
    return closingDays.filter(function (it) {
        return DateTimeUtils.isDateInRange(datetime, it.from, it.to);
    });
};
var getShiftForDate = function (datetime, shifts) {
    return filterShiftsForDate(datetime, shifts).find(function (it) {
        return DateTimeUtils.isTimeInRange(datetime, it.from, it.to);
    });
};
// Check if restaurant closed all day at provided datetime
var isClosed = function (datetime, configuration) {
    var allAreas = configuration.virtualAreas.flatMap(function (va) { return va.areas; });
    return filterClosingDaysForDate(datetime, configuration.closingDays).some(function (it) {
        if (allAreas.some(function (area) { return !area.areaClosingDays.includes(it.id); })) {
            return false;
        }
        var dateFrom = DateTime.fromFormat(it.from, SERVER_DATE_FORMAT).plus({ day: 1 });
        var dateTo = DateTime.fromFormat(it.to, SERVER_DATE_FORMAT);
        return !it.isRecurrent && ((!it.fromTime && !it.toTime) || (datetime >= dateFrom && datetime < dateTo));
    });
};
var isRelevantClosingDay = function (closingDay, shiftAreas) {
    return shiftAreas.every(function (areaId) { var _a; return (_a = closingDay.areas) === null || _a === void 0 ? void 0 : _a.includes(areaId); });
};
// Return list of base shifts which are bookable (time range not inside any closing day)
// TODO: refactor to include areas in the calculation
var getAvailableShiftsForDate = function (datetime, shifts, configuration) {
    var areas = configuration.virtualAreas.flatMap(function (it) { return it.areas; });
    var filteredClosingDays = filterClosingDaysForDate(datetime, configuration.closingDays).map(function (closingDay) { return (__assign(__assign({}, closingDay), { areas: areas.filter(function (area) { return area.areaClosingDays.includes(closingDay.id); }).map(function (area) { return area.id; }) })); });
    if (filteredClosingDays.length === 0) {
        return shifts;
    }
    var shiftAreas = configuration.virtualAreas
        .flatMap(function (va) { return va.areas; })
        .reduce(function (acc, area) {
        area.areaShifts.forEach(function (areaShift) {
            if (acc[areaShift.shiftId]) {
                acc[areaShift.shiftId].push(area.id);
            }
            else {
                acc[areaShift.shiftId] = [area.id];
            }
        });
        return acc;
    }, {});
    return shifts.filter(function (shift) {
        var from = DateTime.fromFormat(shift.from, TIME_FORMAT, { zone: 'utc' });
        var to = DateTime.fromFormat(shift.to, TIME_FORMAT, { zone: 'utc' }).minus({
            seconds: shift.expectedOccupancyTime,
        });
        var relevantClosingDays = filteredClosingDays.filter(function (closingDay) {
            return isRelevantClosingDay(closingDay, shiftAreas[shift.id] || []);
        });
        return relevantClosingDays.every(function (closingDay) {
            var fromTime = closingDay.fromTime, toTime = closingDay.toTime;
            if (!fromTime || !toTime) {
                // All day closed (should never happen since we check before with isClosed if closed all day)
                return false;
            }
            var date = datetime.toFormat(SERVER_DATE_FORMAT);
            var timeFrom = DateTime.fromFormat(fromTime, TIME_FORMAT, { zone: 'utc' });
            var timeTo = DateTime.fromFormat(toTime, TIME_FORMAT, { zone: 'utc' });
            // specialClosedDays are recurrent or specialClosedDay is one day and same as date to check
            if (closingDay.isRecurrent || (date === closingDay.from && date === closingDay.to)) {
                return !isTimeInRange(from, timeFrom, timeTo) || !isTimeInRange(to, timeFrom, timeTo);
            }
            // specialClosedDays period, and date to check is starting date of period
            if (date === closingDay.from) {
                return to <= timeFrom || from < timeFrom;
            }
            // specialClosedDays period, and date to check is last date of period
            if (date === closingDay.to) {
                return from >= timeTo || to > timeTo;
            }
            // specialClosedDays period, and date to check is between start and end of period
            return false;
        });
    });
};
function isTimeInRange(shiftTime, closingDayFrom, closingDayTo) {
    var isTimeFromValid = shiftTime.hour > closingDayFrom.hour ||
        (shiftTime.hour === closingDayFrom.hour && shiftTime.minute >= closingDayFrom.minute);
    var isTimeToValid = shiftTime.hour < closingDayTo.hour ||
        (shiftTime.hour === closingDayTo.hour && shiftTime.minute < closingDayTo.minute);
    return isTimeFromValid && isTimeToValid;
}
var hasShiftForDate = function (datetime, configuration, strict) {
    if (strict === void 0) { strict = false; }
    var shifts = getAvailableShiftsForDate(datetime, filterShiftsForDate(datetime, configuration.shifts), configuration);
    if (!strict) {
        return shifts.length > 0;
    }
    return getShiftForDate(datetime, shifts) !== undefined;
};
//todo Check this calculation if it needs to be changed
var getNextOpeningDates = function (restaurant, amountOfDates) {
    var date = DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    var shifts = restaurant.configuration.shifts;
    var validShifts = shifts.filter(function (s) { return s.enabled && s.shiftType === 'recurrent'; });
    var validSpecialOpeningHours = shifts.filter(function (soh) {
        return soh.enabled &&
            soh.shiftType === 'soh' &&
            soh.dates.map(function (date) { return DateTime.fromFormat(date, SERVER_DATE_FORMAT); }).some(function (date1) { return date1 >= date; });
    });
    if (validShifts.length === 0 && validSpecialOpeningHours.length === 0) {
        return [];
    }
    var dates = [];
    var conditionallyPushDate = function () {
        if (hasShiftForDate(date, restaurant.configuration)) {
            dates.push(date.toFormat(SERVER_DATE_FORMAT));
        }
        date = date.plus({ day: 1 });
    };
    if (validShifts.length === 0) {
        var endDate = validSpecialOpeningHours.reduce(function (end, it) {
            if (it.shiftType !== 'soh') {
                return end;
            }
            //todo Check this calculation if it needs to be changed
            var toDate = DateTime.fromFormat(it.dates[it.dates.length - 1], SERVER_DATE_FORMAT);
            return end > toDate ? end : toDate;
        }, date);
        while (dates.length < amountOfDates && date < endDate) {
            conditionallyPushDate();
        }
    }
    else {
        while (dates.length < amountOfDates) {
            conditionallyPushDate();
        }
    }
    return dates;
};
var parseToDateTime = function (date, time, noticeTime) {
    return DateTime.fromFormat("".concat(date, " ").concat(time), "".concat(DATE_FORMAT, " ").concat(TIME_FORMAT), { zone: 'utc' })
        .toLocal()
        .minus({ seconds: noticeTime });
};
var getShiftOptionsForDate = function (date, configuration, language) {
    return getRawShiftsForDate(date, configuration)
        .map(function (it) { return ({
        label: it.name[language],
        value: it.id,
        disabled: DateTime.local() > parseToDateTime(date, it.to, it.minReservationNotice),
        shift: it,
    }); })
        .sort(function (a, b) {
        return DateTime.fromFormat(a.value, TIME_FORMAT) < DateTime.fromFormat(b.value, TIME_FORMAT) ? -1 : 1;
    });
};
var getRawShiftsForDate = function (date, configuration) {
    var datetime = DateTime.fromFormat(date, DATE_FORMAT);
    var specialOpeningHours = getAvailableShiftsForDate(datetime, filterShiftsForDate(datetime, configuration.shifts).filter(function (s) { return s.shiftType === 'soh'; }), configuration);
    if (specialOpeningHours.length) {
        return specialOpeningHours;
    }
    else {
        var shifts = getAvailableShiftsForDate(datetime, filterShiftsForDate(datetime, configuration.shifts).filter(function (s) { return s.shiftType === 'recurrent'; }), configuration);
        return shifts;
    }
};
var groupTimeSlotsForShift = function (availableTimeSlots, showAll) {
    var options = { maxCols: 3, maxRows: 2 };
    var index = 0;
    var groups = availableTimeSlots.reduce(function (acc, listItem) {
        var row = acc[index] || [];
        if (!showAll && row.length === options.maxCols && acc.length === options.maxRows) {
            return acc;
        }
        var option = {
            label: listItem.timeSlot,
            value: listItem.timeSlot,
            disabled: false,
        };
        if (row.length === options.maxCols) {
            index++;
            row = [option];
            acc.push(row);
        }
        else {
            row.push(option);
            acc[index] = row;
        }
        return acc;
    }, []);
    return { groups: groups, hasMore: !showAll && availableTimeSlots.length > options.maxCols * options.maxRows };
};
var findShiftById = function (shifts, shiftId) {
    return shifts.find(function (shift) { return shift.id === shiftId; });
};
export var getShiftFrom = function (shifts, shiftId) {
    var shift = findShiftById(shifts, shiftId);
    return shift ? shift.from : '';
};
export var getDefaultArea = function (configuration) {
    return configuration.virtualAreas
        .flatMap(function (virtualArea) { return virtualArea.areas; })
        .find(function (area) { return area.id === configuration.defaultAreaId; });
};
var getAreaTablePlans = function (configuration, areaId) {
    var _a;
    return (((_a = configuration.virtualAreas.flatMap(function (virtualArea) { return virtualArea.areas; }).find(function (area) { return area.id === areaId; })) === null || _a === void 0 ? void 0 : _a.tablePlans) || []);
};
var getTableArea = function (configuration, tableId) {
    return configuration === null || configuration === void 0 ? void 0 : configuration.virtualAreas.flatMap(function (v) { return v.areas; }).find(function (area) {
        return area.tablePlans.flatMap(function (tp) { return tp.tables; }).find(function (t) { return t.id === tableId; });
    });
};
var getAreaShiftList = function (shift, configuration) {
    var _a;
    var assignedAreas = [];
    (_a = configuration.virtualAreas) === null || _a === void 0 ? void 0 : _a.forEach(function (virtualArea) {
        virtualArea.areas.forEach(function (area) {
            var _a;
            (_a = area === null || area === void 0 ? void 0 : area.areaShifts) === null || _a === void 0 ? void 0 : _a.forEach(function (areaShift) {
                if (areaShift.shiftId === shift.id) {
                    assignedAreas.push({
                        areaId: area.id,
                        directlyBookable: areaShift.directlyBookable,
                        capacityPercentage: areaShift.capacityPercentage,
                        tablePlanId: areaShift.tablePlanId,
                    });
                }
            });
        });
    });
    return assignedAreas;
};
var getVirtualAreaShiftList = function (shift, configuration) {
    var _a;
    var assignedAreas = [];
    (_a = configuration.virtualAreas) === null || _a === void 0 ? void 0 : _a.forEach(function (virtualArea) {
        virtualArea.areas.forEach(function (area) {
            var _a;
            (_a = area === null || area === void 0 ? void 0 : area.areaShifts) === null || _a === void 0 ? void 0 : _a.forEach(function (areaShift) {
                if (areaShift.shiftId === shift.id && !areaShift.directlyBookable) {
                    assignedAreas.push({
                        areaId: virtualArea.id,
                        directlyBookable: true,
                        capacityPercentage: areaShift.capacityPercentage,
                        tablePlanId: areaShift.tablePlanId,
                    });
                }
            });
        });
    });
    return assignedAreas;
};
var getAreasForClosingDay = function (closingDay, configuration) {
    var _a;
    var areas = [];
    (_a = configuration.virtualAreas) === null || _a === void 0 ? void 0 : _a.forEach(function (virtualArea) {
        virtualArea.areas.forEach(function (area) {
            var _a;
            (_a = area === null || area === void 0 ? void 0 : area.areaClosingDays) === null || _a === void 0 ? void 0 : _a.forEach(function (areaClosingDay) {
                if (areaClosingDay === closingDay.id) {
                    areas.push(area.id);
                }
            });
        });
    });
    return areas;
};
var isPeriodInsideClosingDay = function (closingDay, from, to, date) {
    var fromTime = DateTime.fromFormat("".concat(date.toFormat(DATE_FORMAT), " ").concat(from), DATE_TIME_FORMAT);
    var toTime = DateTime.fromFormat("".concat(date.toFormat(DATE_FORMAT), " ").concat(to), DATE_TIME_FORMAT);
    var closingDayFromTime = DateTime.fromFormat("".concat(!closingDay.isRecurrent ? closingDay.from : date.toFormat(SERVER_DATE_FORMAT), " ").concat(closingDay.fromTime || '00:00'), SERVER_DATE_TIME_FORMAT);
    var closingDayToTime = DateTime.fromFormat("".concat(!closingDay.isRecurrent ? closingDay.to : date.toFormat(SERVER_DATE_FORMAT), " ").concat(closingDay.toTime || '23:59'), SERVER_DATE_TIME_FORMAT);
    return fromTime.valueOf() >= closingDayFromTime.valueOf() && toTime.valueOf() <= closingDayToTime.valueOf();
};
var isClosedAndHasNoShiftsOnDate = function (date, configuration) {
    return !!configuration && (isClosed(date, configuration) || Boolean(!getShiftsForDate(date, configuration).length));
};
var getShiftsForDate = function (datetime, configuration) {
    if (!configuration) {
        return [];
    }
    var shifts = filterShiftsForDate(datetime, configuration.shifts).filter(function (s) { return s.shiftType === 'recurrent'; });
    var specialOpeningHours = filterShiftsForDate(datetime, configuration.shifts).filter(function (s) { return s.shiftType === 'soh'; });
    var closingDays = filterClosingDaysForDate(datetime, configuration.closingDays);
    var specialOpeningHoursRes = specialOpeningHours.map(function (shift) {
        return {
            id: shift.id,
            shift: shift,
            areas: configuration.virtualAreas
                .flatMap(function (va) { return va.areas; })
                .filter(function (area) {
                return closingDays
                    .filter(function (cl) { return area.areaClosingDays.includes(cl.id); })
                    .reduce(function (acc, closingDay) {
                    return acc && !isPeriodInsideClosingDay(closingDay, shift.from, shift.to, datetime);
                }, true);
            })
                .filter(function (area) { return area.areaShifts.some(function (as) { return as.shiftId === shift.id; }); })
                .map(function (area) { return ({
                id: area.id,
                name: area.name,
                code: area.code,
                type: 'regular',
            }); }),
        };
    });
    var sohAreas = specialOpeningHoursRes.flatMap(function (s) { return s.areas; });
    var shiftRes = shifts.map(function (shift) {
        return {
            id: shift.id,
            shift: shift,
            areas: configuration.virtualAreas
                .flatMap(function (va) { return va.areas; })
                .filter(function (area) {
                return closingDays
                    .filter(function (cl) { return area.areaClosingDays.includes(cl.id); })
                    .reduce(function (acc, closingDay) {
                    return acc && !isPeriodInsideClosingDay(closingDay, shift.from, shift.to, datetime);
                }, true);
            })
                .filter(function (area) { return area.areaShifts.some(function (as) { return as.shiftId === shift.id; }); })
                .filter(function (area) { return !sohAreas.some(function (sa) { return sa.id === area.id; }); })
                .map(function (area) { return ({
                id: area.id,
                name: area.name,
                code: area.code,
                type: 'regular',
            }); }),
        };
    });
    configuration.virtualAreas.forEach(function (virtualArea) {
        specialOpeningHoursRes.forEach(function (soh) {
            if (virtualArea.areas.find(function (area) { return soh.areas.find(function (sa) { return sa.id === area.id; }) !== undefined; }) !== undefined) {
                soh.areas.push({
                    id: virtualArea.id,
                    name: virtualArea.name,
                    code: virtualArea.code,
                    type: 'virtual',
                });
            }
        });
        shiftRes.forEach(function (shift) {
            if (virtualArea.areas.find(function (area) { return shift.areas.find(function (sa) { return sa.id === area.id; }) !== undefined; }) !==
                undefined) {
                shift.areas.push({
                    id: virtualArea.id,
                    name: virtualArea.name,
                    code: virtualArea.code,
                    type: 'virtual',
                });
            }
        });
    });
    return __spreadArray(__spreadArray([], shiftRes, true), specialOpeningHoursRes, true).filter(function (s) { return s.areas.length > 0; });
};
var generateSlotsForShift = function (configuration, shiftId, reservationDateTime) {
    if (!configuration || !shiftId) {
        return [];
    }
    var shift = configuration.shifts.find(function (soh) { return soh.id === shiftId; });
    if (!shift) {
        return [];
    }
    var reservationTime = DateTime.fromFormat(reservationDateTime || shift.from, TIME_FORMAT);
    var endTime = DateTime.fromFormat(shift.to, TIME_FORMAT);
    var slots = [];
    var denominator = shift.slotInterval / 60;
    var size = denominator;
    var addedExpected = false;
    while (reservationTime.plus({ minutes: size }).diff(endTime).valueOf() <= 0) {
        if (!addedExpected && size * 60 === shift.expectedOccupancyTime) {
            addedExpected = true;
        }
        if (!addedExpected && size * 60 > shift.expectedOccupancyTime) {
            addedExpected = true;
            slots.push("".concat(shift.expectedOccupancyTime / 60));
        }
        slots.push("".concat(size));
        size += denominator;
    }
    if (!addedExpected && reservationTime.plus({ seconds: shift.expectedOccupancyTime }).diff(endTime).valueOf() <= 0) {
        slots.push("".concat(shift.expectedOccupancyTime / 60));
    }
    return slots;
};
var formatRestaurantAddress = function (_a) {
    var street = _a.street, streetNumber = _a.streetNumber, postalCode = _a.postalCode, city = _a.city;
    return "".concat(street, " ").concat(streetNumber, ", ").concat(postalCode, " ").concat(city);
};
export var RestaurantUtils = {
    filterShiftsForDate: filterShiftsForDate,
    findShiftById: findShiftById,
    formatRestaurantAddress: formatRestaurantAddress,
    generateSlotsForShift: generateSlotsForShift,
    getAreaShiftList: getAreaShiftList,
    getAreaTablePlans: getAreaTablePlans,
    getAreasForClosingDay: getAreasForClosingDay,
    getDefaultArea: getDefaultArea,
    getNextOpeningDates: getNextOpeningDates,
    getRawShiftsForDate: getRawShiftsForDate,
    getShiftForDate: getShiftForDate,
    getShiftFrom: getShiftFrom,
    getShiftOptionsForDate: getShiftOptionsForDate,
    getShiftsForDate: getShiftsForDate,
    getTableArea: getTableArea,
    getVirtualAreaShiftList: getVirtualAreaShiftList,
    groupTimeSlotsForShift: groupTimeSlotsForShift,
    hasShiftForDate: hasShiftForDate,
    isClosed: isClosed,
    isClosedAndHasNoShiftsOnDate: isClosedAndHasNoShiftsOnDate,
};

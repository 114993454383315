import { Tabs, useConfirmContext } from '@localina/core';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Path } from '../../../enums';
import { PathUtils } from '../../../utils';
import { Page } from '../../Page';
import { MenuAppearance } from './MenuAppearance';
import MenuEditor from './MenuEditor';
import MenuIntegrations from './MenuIntegrations';

interface IIntegrationMenuPathParams {
    restaurantId: string;
}

const MenuIntegrationPage = () => {
    const { t } = useTranslation();
    const { confirm } = useConfirmContext();
    const navigate = useNavigate();
    const params = useParams() as unknown as IIntegrationMenuPathParams;

    const onBack = useCallback(() => {
        navigate(
            PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_INTEGRATIONS, {
                restaurantId: params.restaurantId,
            }),
        );
    }, [params.restaurantId]);

    const tabs = [
        { label: t('integrations.items.menu.tabs.editor'), value: 'editor' },
        { label: t('integrations.items.menu.tabs.appearance.title'), value: 'appearance' },
        { label: t('integrations.items.menu.tabs.integrations.title'), value: 'integrations' },
    ];

    const [activeTab, setActiveTab] = React.useState(tabs[0].value);
    const [contentNotSaved, setContentNotSaved] = React.useState(false);

    const handleChangeTab = async (newTab: string) => {
        if (
            !contentNotSaved ||
            (await confirm({
                title: t('integrations.items.menu.infoMessages.unsavedChangesTitle'),
                msg: t('integrations.items.menu.infoMessages.unsavedChangesMessage'),
            })) === 'no'
        ) {
            setActiveTab(newTab);
        }
    };

    useEffect(() => {
        setContentNotSaved(false);
    }, [activeTab]);

    return (
        <Page name="menu" title={{ value: t('integrations.items.menu.title'), onBack }}>
            <Tabs tabs={tabs} active={activeTab} onChange={handleChangeTab} className={'menu-integration-tabs'} />
            <div className={'tab-container'}>
                {activeTab === 'editor' && (
                    <MenuEditor contentNotSaved={contentNotSaved} setContentNotSaved={setContentNotSaved} />
                )}
                {activeTab === 'appearance' && <MenuAppearance setContentNotSaved={setContentNotSaved} />}
                {activeTab === 'integrations' && <MenuIntegrations />}
            </div>
        </Page>
    );
};

export default MenuIntegrationPage;

import { IAvailableAreaTimeRequested } from '@localina/core';
import { ExportFormat } from '../../enums';
import { IGuestListFilters } from './guests';
import { IGetReportExportFilters, IGetReportFilters } from './reports';
import { ISalesStatisticsFilters } from './salesStatistics';

const queryKeys = {
    restaurants: {
        all: ['restaurants'] as const,
        single: {
            stats: (restaurantId: string, dates: string[]) =>
                [...queryKeys.restaurants.all, restaurantId, 'stats', { dates }] as const,
            messages: {
                all: (restaurantId: string) => [...queryKeys.restaurants.all, restaurantId, 'messages'] as const,
                single: (restaurantId: string, messageId: string) =>
                    [...queryKeys.restaurants.single.messages.all(restaurantId), messageId] as const,
            },
            reports: {
                all: (restaurantId: string) => [...queryKeys.restaurants.all, restaurantId, 'reports'] as const,
                single: {
                    get: (restaurantId: string, filters: IGetReportFilters) =>
                        [...queryKeys.restaurants.single.reports.all(restaurantId), 'get', filters] as const,
                    export: (restaurantId: string, filters: IGetReportExportFilters) =>
                        [...queryKeys.restaurants.single.reports.all(restaurantId), 'export', filters] as const,
                },
            },
            guests: {
                all: (restaurantId: string) => [...queryKeys.restaurants.all, restaurantId, 'guests'] as const,
                filtered: (restaurantId: string, filters: IGuestListFilters) =>
                    [
                        ...queryKeys.restaurants.single.guests.all(restaurantId),
                        'all',
                        filters.query,
                        filters.sort?.by || '',
                        filters.sort?.order || '',
                    ] as const,
                single: (restaurantId: string, guestId: string) =>
                    [...queryKeys.restaurants.single.guests.all(restaurantId), 'single', guestId] as const,
                guestReservations: (restaurantId: string, guestId: string) =>
                    [...queryKeys.restaurants.single.guests.single(restaurantId, guestId), 'reservations'] as const,
            },
            reservations: {
                all: (restaurantId: string) => [...queryKeys.restaurants.all, restaurantId, 'reservations'] as const,
                onDate: (restaurantId: string, date: string) =>
                    [...queryKeys.restaurants.single.reservations.all(restaurantId), date] as const,
                dailyExport: (restaurantId: string, date: string) =>
                    [...queryKeys.restaurants.single.reservations.all(restaurantId), 'export-daily', date] as const,
                single: (restaurantId: string, reservationId: string) =>
                    [...queryKeys.restaurants.single.reservations.all(restaurantId), 'single', reservationId] as const,
            },
            availableAreaTimeSlot: (restaurantId: string, variables: IAvailableAreaTimeRequested) =>
                [
                    ...queryKeys.restaurants.all,
                    restaurantId,
                    'timeSlot',
                    variables.reservationDateTime,
                    variables.participants,
                    variables.areaId,
                    variables.shiftId,
                    variables.expectedOccupancyTime,
                ] as const,
            dailyNotes: {
                all: (restaurantId: string) => [...queryKeys.restaurants.all, restaurantId, 'daily-notes'] as const,
                single: (restaurantId: string, date: string) =>
                    [...queryKeys.restaurants.single.dailyNotes.all(restaurantId), date] as const,
            },
            notifications: {
                unreadReservations: (restaurantId: string) =>
                    [...queryKeys.restaurants.all, restaurantId, 'notifications', 'unread-reservations'] as const,
            },
            tracing: {
                export: (restaurantId: string, format: ExportFormat) =>
                    [...queryKeys.restaurants.all, restaurantId, 'tracing', 'export', format] as const,
            },
            documents: {
                single: (restaurantId: string, id: string) =>
                    [...queryKeys.restaurants.all, restaurantId, 'documents', id] as const,
            },
            menus: {
                all: (restaurantId: string) => [...queryKeys.restaurants.all, restaurantId, 'menus'] as const,
                filtered: (restaurantId: string, dateFrom: string, dateTo: string) =>
                    [...queryKeys.restaurants.single.menus.all(restaurantId), dateFrom, dateTo] as const,
            },
        },
    } as const,
    pendingReservation: (token: string) => ['pending-reservations', token] as const,
    importProgress: {
        all: ['import-progresses'] as const,
    },
    auth: ['auth-user'] as const,
    accounts: {
        all: ['accounts'] as const,
        single: (accountId: string) => [...queryKeys.accounts.all, accountId] as const,
    } as const,
    users: {
        all: ['users'] as const,
        single: (userId: string) => [...queryKeys.users.all, userId] as const,
    },
    support: {
        accounts: (query = '') => ['support', 'accounts', query] as const,
        users: {
            all: ['support', 'users'] as const,
            single: (userId: string) => [...queryKeys.support.users.all, userId] as const,
        },
        statistics: (filter: ISalesStatisticsFilters) => [
            'support',
            'statistics',
            filter.query,
            filter.dateFrom,
            filter.dateTo,
        ],
    },
    platforms: {
        all: ['platforms'] as const,
        active: () => [...queryKeys.platforms.all, 'active'] as const,
    },
};

export { queryKeys };

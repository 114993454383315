import {
    ButtonGroup,
    createEmptyShift,
    DATE_FORMAT,
    DateRangeField,
    DateTimeUtils,
    defaultShiftNames,
    defaultShiftNames as shiftNames,
    defaultShiftTimes as shiftTimes,
    IButtonGroupOption,
    ILocalizedName,
    IRestaurantArea,
    IRestaurantAreaShift,
    IRestaurantShift,
    IRestaurantShiftUpdate,
    ISelectOption,
    IShiftArea,
    IShiftBlockedSlots,
    Label,
    RestaurantUtils,
    SelectField,
    SERVER_DATE_FORMAT,
    Switch,
    TextField,
    TIME_FORMAT,
    TimeField,
    useConfirmContext,
    ValidationUtils,
} from '@localina/core';
import Typography from '@mui/material/Typography';
import { DateTime, Info } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useVirtualAreas } from '../../api/queries/restaurantAreas';
import { IRestaurantAreaAccordionPreview, IRestaurantVirtualAreaShiftPreview } from '../../interfaces';
import { AreaAccordionItem } from '../AreaAccordionItem/AreaAccordionItem';
import { Drawer } from '../Drawer';
import RequestModeSwitchWithSlider from '../RequestModeSwitchWithSlider/RequestModeSwitchWithSlider';
import { UserFeature } from '../UserFeature';
import BlockedSlots from './BlockedSlots';

interface IProps {
    restaurantId?: string;
    hasError: boolean;
    error?: any;
    disableConfirmation?: boolean;
    canAccessAreas?: boolean;
    canAccessTablePlan?: boolean;
    shift?: IRestaurantShift;
    type: 'soh' | 'recurrent';
    open: boolean;
    onSave: (shift: IRestaurantShiftUpdate) => void;
    onClose: () => void;
    onDelete: (shift: IRestaurantShift) => void;
}

type TShiftName = keyof typeof defaultShiftNames;

export const ShiftView: React.FC<IProps> = (props) => {
    const mode = props.shift ? props.shift.shiftType : props.type;
    const { i18n, t } = useTranslation();
    const i18nLanguage = i18n.language as keyof ILocalizedName;
    const { confirm } = useConfirmContext();
    const [shift, setShift] = React.useState(createEmptyShift(mode));
    const [actionRequested, setActionRequested] = React.useState(false);
    const [isDeleting, setDeleting] = React.useState(false);

    const [areaList, setAreaList] = React.useState<IRestaurantAreaAccordionPreview[]>([]);
    const [areaShiftList, setAreaShiftList] = React.useState<IShiftArea[]>([]);
    const isFormValid = shift && validateShift(shift);

    const virtualAreasQuery = useVirtualAreas(props.restaurantId);

    const virtualAreaPreviewList = (() => {
        const virtualAreas: IRestaurantVirtualAreaShiftPreview[] = [];
        virtualAreasQuery.data?.virtualAreas.forEach((virtualArea) => {
            let capacity = 0;
            areaList.forEach((area) => {
                if (
                    !area.directlyBookable &&
                    area.virtualArea === virtualArea.name &&
                    areaShiftList.findIndex((areaShift) => areaShift.areaId === area.id) !== -1
                ) {
                    capacity += (area.capacity * area.capacityPercentage) / 100;
                }
            });
            if (capacity !== 0) {
                virtualAreas.push({ name: virtualArea.name, capacity: capacity });
            }
        });
        return virtualAreas;
    })();

    const previewCapacity = (() => {
        let capacity = 0;
        areaList.forEach((area) => {
            if (area.directlyBookable && areaShiftList.findIndex((areaShift) => areaShift.areaId === area.id) !== -1) {
                capacity += (area.capacity * area.capacityPercentage) / 100;
            }
        });
        virtualAreaPreviewList.forEach((virtualArea) => {
            capacity += virtualArea.capacity;
        });
        return capacity;
    })();
    const groupButtonLabels = (() => {
        const buttonArray: IButtonGroupOption[] = [];
        areaList.forEach((area) => {
            if (
                area.directlyBookable &&
                area.capacityPercentage &&
                areaShiftList.findIndex((areaShift) => areaShift.areaId === area.id) !== -1
            ) {
                buttonArray.push({
                    value: area.id,
                    label: area.name,
                });
            }
        });
        virtualAreaPreviewList.forEach((virtualArea) => {
            buttonArray.push({
                value: '',
                label: virtualArea.name,
            });
        });
        return buttonArray;
    })();

    const nameOptions: ISelectOption[] = (Object.keys(shiftNames) as TShiftName[]).map((name) => ({
        label: shiftNames[name][i18nLanguage],
        value: name,
    }));

    const dayOptions: IButtonGroupOption[] = Info.weekdays('short', { locale: i18n.language }).map((weekday, i) => ({
        label: weekday,
        value: i + 1,
    }));
    React.useEffect(() => {
        if (props.shift && props.open) {
            setShift(props.shift);
        } else {
            setShift(createEmptyShift(mode));
        }
        setActionRequested(false);
        prepareListOfArea();
    }, [props.shift, mode, props.open]);

    const prepareListOfArea = () => {
        const areas: IRestaurantAreaAccordionPreview[] = [];
        const assignedAreas: IShiftArea[] = [];
        virtualAreasQuery.data?.virtualAreas.forEach((virtualArea) => {
            virtualArea.areas.forEach((area: IRestaurantArea) => {
                let capacityPercentage = 100;
                let directlyBookable = true;
                let isEnabled = area.name === 'Restaurant' && !props.shift;
                let tablePlanId = '';
                if (props.shift) {
                    area?.areaShifts?.forEach((areaShift: IRestaurantAreaShift) => {
                        if (areaShift.shiftId === props.shift?.id) {
                            capacityPercentage = areaShift.capacityPercentage;
                            directlyBookable = areaShift.directlyBookable;
                            isEnabled = true;
                            tablePlanId = areaShift.tablePlanId;
                            assignedAreas.push({
                                areaId: area.id,
                                directlyBookable,
                                capacityPercentage,
                                tablePlanId,
                            });
                        }
                    });
                }
                const shiftArea: IRestaurantAreaAccordionPreview = {
                    name: area.name,
                    id: area.id,
                    capacity: area.capacity,
                    capacityPercentage,
                    directlyBookable,
                    virtualArea: virtualArea.name,
                    isEnabled,
                    tablePlans: area.tablePlans,
                    tablePlanId,
                };
                areas.push(shiftArea);
                if (area.name === 'Restaurant' && !props.shift) {
                    assignedAreas.push({
                        areaId: area.id,
                        directlyBookable,
                        capacityPercentage,
                        tablePlanId: '',
                    });
                }
            });
        });
        setAreaList(areas);
        setAreaShiftList(assignedAreas);
    };

    const handleEnabledChange = (value: boolean) => {
        setShift((prevState) => {
            if (!prevState) {
                return prevState;
            }
            return {
                ...prevState,
                enabled: value,
            };
        });
    };

    const handleRestrictReservationsInActiveShiftChange = (value: boolean) => {
        setShift((prevState) => {
            if (!prevState) {
                return prevState;
            }
            return {
                ...prevState,
                restrictReservationsWhenActive: value,
            };
        });
    };

    const handleNameChange = (value: TShiftName) => {
        setShift((prev) => {
            if (!prev) {
                return prev;
            }
            const name = shiftNames[value];
            const times = shiftTimes[value];
            return {
                ...prev,
                name: name,
                from: times?.from ?? '',
                to: times?.to ?? '',
            };
        });
    };
    const handleRequestModeActivationPercentageChange = (requestModeActivationPercentage: number | null) => {
        setShift((prevState) => ({
            ...prevState,
            requestModeActivationPercentage,
        }));
    };

    const handleDatesChange = (value: string | string[]) => {
        setShift((prev) => {
            if (!prev || prev.shiftType === 'recurrent') {
                return prev;
            }
            return {
                ...prev,
                dates: (Array.isArray(value) ? value : [value]).map((val) =>
                    DateTime.fromFormat(val, DATE_FORMAT).toFormat(SERVER_DATE_FORMAT),
                ),
            };
        });
    };

    const handleTimeChange = (field: string) => (value: string) => {
        setShift((prev) => {
            if (!prev) {
                return prev;
            }
            const time = DateTime.fromFormat(value, TIME_FORMAT);
            return {
                ...prev,
                [field]: time.isValid ? time.toFormat(TIME_FORMAT) : '',
            };
        });
    };

    const handleDaysChange = (value: number[]) => {
        setShift((prev) => {
            if (!prev) {
                return prev;
            }
            return {
                ...prev,
                occurrence: value,
            };
        });
    };

    const handleNumberChange = (key: string, value: string) => {
        setShift((prev) => {
            if (!prev) {
                return prev;
            }
            return {
                ...prev,
                [key]: value !== '' ? +value * 60 : undefined,
            };
        });
    };

    const handleExpectedOccupancyTimeChange = (value: string) => {
        handleNumberChange('expectedOccupancyTime', value);
    };

    const handleSlotIntervalChange = (value: string) => {
        handleNumberChange('slotInterval', value);
    };

    const handleMinReservationNoticeChange = (value: string) => {
        handleNumberChange('minReservationNotice', value);
    };

    const handleClose = () => {
        props.onClose();
    };

    const getConfirm = async (msg: string, title: string) => {
        if (props.disableConfirmation || !props.shift) {
            return true;
        } else {
            return (
                (await confirm({
                    msg,
                    title,
                })) === 'yes'
            );
        }
    };

    const handleSave = async () => {
        const confirmed = await getConfirm(t('availability.dialog.edit.message'), t('availability.dialog.edit.title'));
        if (!confirmed) {
            return;
        }
        if (shift) {
            setActionRequested(true);
            setDeleting(false);
            props.onSave({
                ...shift,
                blockedSlots: shift.blockedSlots.filter((slot) => slot.from && slot.to),
                shiftAreas: areaShiftList,
            });
        }
    };

    const handleDelete = async () => {
        if (!props.shift) {
            return;
        }
        const confirmed = await getConfirm(
            t('availability.dialog.delete.message', {
                name: shift ? shift.name[i18nLanguage] : '',
            }),
            t('availability.dialog.delete.title'),
        );
        if (!confirmed) {
            return;
        }
        setActionRequested(true);
        setDeleting(true);
        props.onDelete(props.shift);
    };

    const getStringFromNumber = (value?: number) => (value ? `${value / 60}` : undefined);

    const handleAssignAreaChange = (value: boolean, areaId: string) => {
        const assignedAreaIndex = areaShiftList.findIndex((area) => area.areaId === areaId);
        const list = areaShiftList;
        if (assignedAreaIndex === -1 && value) {
            list.push({ areaId: areaId, capacityPercentage: 100, directlyBookable: true, tablePlanId: '' });
        }
        if (assignedAreaIndex !== -1 && !value) {
            list.splice(assignedAreaIndex, 1);
        }
        setAreaShiftList(list);
        if (shift) {
            const index = areaList.findIndex((area) => area.id === areaId);
            areaList[index].isEnabled = value;
            setShift((prevState) => {
                if (!prevState) {
                    return prevState;
                }
                return {
                    ...prevState,
                    shiftAreas: list,
                };
            });
        }
    };

    const handleAreaDirectlyBookableChange = (value: boolean, areaId: string) => {
        if (shift && areaShiftList.findIndex((area) => area.areaId === areaId) !== -1) {
            const index = areaList.findIndex((area) => area.id === areaId);
            areaList[index].directlyBookable = value;
            const areaShiftIndex = areaShiftList.findIndex((area) => area.areaId === areaId);
            areaShiftList[areaShiftIndex].directlyBookable = value;
            setShift((prevState) => {
                if (!prevState) {
                    return prevState;
                }
                return {
                    ...prevState,
                    shiftAreas: areaShiftList.map((area) => ({
                        areaId: area.areaId,
                        directlyBookable: area.directlyBookable,
                        capacityPercentage: area.capacityPercentage,
                        tablePlanId: area.tablePlanId,
                    })),
                };
            });
        }
    };

    const handleTablePlanChange = (value: string, areaId: string) => {
        if (shift && areaShiftList.findIndex((area) => area.areaId === areaId) !== -1) {
            const index = areaList.findIndex((area) => area.id === areaId);
            areaList[index].tablePlanId = value;
            const areaShiftIndex = areaShiftList.findIndex((area) => area.areaId === areaId);
            areaShiftList[areaShiftIndex].tablePlanId = value;
            setShift((prevState) => {
                if (!prevState) {
                    return prevState;
                }
                return {
                    ...prevState,
                    shiftAreas: areaShiftList.map((area) => ({
                        areaId: area.areaId,
                        directlyBookable: area.directlyBookable,
                        capacityPercentage: area.capacityPercentage,
                        tablePlanId: area.tablePlanId,
                    })),
                };
            });
        }
    };

    const handleSliderChange = (value: number, areaId: string) => {
        setAreaList((prev) =>
            prev.map((element) => {
                if (element.id === areaId) {
                    return {
                        ...element,
                        capacityPercentage: value,
                    };
                }
                return element;
            }),
        );
        setAreaShiftList((prev) =>
            prev.map((element) => {
                if (element.areaId === areaId) {
                    return {
                        ...element,
                        capacityPercentage: value,
                    };
                }
                return element;
            }),
        );
        setShift((prev) => {
            if (!prev) {
                return prev;
            }
            return {
                ...prev,
                shiftAreas: areaShiftList.map((area) => {
                    if (area.areaId === areaId) {
                        return {
                            ...area,
                            capacityPercentage: value,
                        };
                    }
                    return area;
                }),
            };
        });
    };
    const setBlockedSlots = (blockedSlots: IShiftBlockedSlots[]) => {
        setShift((prevState) => {
            if (!prevState) {
                return prevState;
            }
            return {
                ...prevState,
                blockedSlots,
            };
        });
    };

    return (
        <Drawer
            className="shift-view"
            open={props.open}
            title={t('availability.title')}
            disabled={!isFormValid}
            error={{
                isVisible: actionRequested && props.hasError,
                message: props.error?.errorKey
                    ? t(props.error.errorKey, props.error.context)
                    : t(`availability.shiftView.errors.${isDeleting ? 'delete' : props.type}`),
            }}
            onClose={handleClose}
            onSave={handleSave}
            onDelete={props.shift ? handleDelete : undefined}
        >
            <div className="group">
                <div className="title-configuration">
                    <Label
                        type="title"
                        value={t('availability.shiftView.title.configuration')}
                        variant="h6"
                        align="left"
                    />
                </div>
                <Switch
                    label={t('availability.shiftView.fields.enabled')}
                    checked={shift.enabled}
                    onChange={handleEnabledChange}
                    tooltip={t('availability.shiftView.fields.enabledInfo')}
                />
                <UserFeature filter="noBookingWhenShiftActive">
                    <Switch
                        label={t('availability.shiftView.fields.disableReservationsIfShiftStarted.title')}
                        checked={shift.restrictReservationsWhenActive}
                        onChange={handleRestrictReservationsInActiveShiftChange}
                        tooltip={t('availability.shiftView.fields.disableReservationsIfShiftStarted.info')}
                    />
                </UserFeature>

                <UserFeature filter="requestMode">
                    <RequestModeSwitchWithSlider
                        activationPercentage={shift.requestModeActivationPercentage}
                        setActivationPercentage={handleRequestModeActivationPercentageChange}
                    />
                </UserFeature>

                {shift.shiftType === 'recurrent' ? (
                    <SelectField
                        label={t('availability.shiftView.fields.name.title')}
                        value={
                            (Object.keys(shiftNames) as TShiftName[]).find((key) => {
                                return shiftNames[key][i18nLanguage] === shift.name[i18nLanguage];
                            }) || ''
                        }
                        options={nameOptions}
                        required
                        onChange={handleNameChange}
                    />
                ) : (
                    <TextField
                        name="name"
                        label={t('availability.shiftView.fields.name.title')}
                        value={shift.name[i18nLanguage]}
                        required={true}
                        onChange={(val) => {
                            setShift((prev) => {
                                if (!prev || prev.shiftType !== 'soh') {
                                    return prev;
                                }
                                return {
                                    ...prev,
                                    name: {
                                        en: val,
                                        de: val,
                                        it: val,
                                        fr: val,
                                    },
                                };
                            });
                        }}
                    />
                )}
                {shift.shiftType === 'soh' && (
                    <div className="row shift-dates">
                        <DateRangeField
                            name="dates"
                            label={t('availability.specialOpeningHourView.fields.dates')}
                            value={shift.dates.map((dat) =>
                                DateTime.fromFormat(dat, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT),
                            )}
                            shouldHighlightDate={(datetime: DateTime) =>
                                Boolean(
                                    virtualAreasQuery.data?.restaurantConfiguration &&
                                        RestaurantUtils.isClosed(
                                            datetime,
                                            virtualAreasQuery.data.restaurantConfiguration,
                                        ),
                                )
                            }
                            required
                            onChange={handleDatesChange}
                        />
                    </div>
                )}
                <div className="row">
                    <TimeField
                        name="from"
                        label={t('availability.shiftView.fields.from')}
                        value={DateTime.fromFormat(shift.from, TIME_FORMAT).toFormat(TIME_FORMAT)}
                        required
                        onChange={handleTimeChange('from')}
                    />
                    <TimeField
                        name="to"
                        label={t('availability.shiftView.fields.to')}
                        value={DateTime.fromFormat(shift.to, TIME_FORMAT).toFormat(TIME_FORMAT)}
                        required
                        onChange={handleTimeChange('to')}
                    />
                </div>
                {shift.shiftType === 'recurrent' && (
                    <>
                        <Label
                            type="label"
                            value={t('availability.shiftView.fields.day.title')}
                            tooltip={t('availability.shiftView.fields.day.info')}
                        />
                        <ButtonGroup
                            value={shift.shiftType === 'recurrent' ? shift.occurrence : []}
                            options={dayOptions}
                            onChange={handleDaysChange}
                        />
                    </>
                )}
                <Label
                    type="label"
                    value={t('availability.shiftView.fields.blockedTimes.title')}
                    tooltip={t('availability.shiftView.fields.blockedTimes.info')}
                />
                <BlockedSlots shift={shift} onChange={setBlockedSlots} />
                <div className="title-capacity">
                    <Label type="title" value={t('availability.shiftView.title.capacity')} variant="h6" align="left" />
                </div>
                <TextField
                    name="expectedOccupancyTime"
                    label={t('availability.shiftView.fields.expectedOccupancyTime')}
                    value={getStringFromNumber(shift.expectedOccupancyTime)}
                    type="number"
                    required={true}
                    helperText={t('availability.shiftView.helperTexts.expectedOccupancyTime')}
                    onChange={handleExpectedOccupancyTimeChange}
                />
                <TextField
                    name="pacing"
                    label={t('availability.shiftView.fields.pacing')}
                    value={`${shift.pacing || ''}`}
                    type="number"
                    helperText={t('availability.shiftView.helperTexts.pacing')}
                    onChange={(val) => {
                        setShift((prev) => {
                            if (!prev) {
                                return prev;
                            }
                            return {
                                ...prev,
                                pacing: Number(val) || undefined,
                            };
                        });
                    }}
                />
                <TextField
                    name="maxPersonsPerReservation"
                    label={t('availability.shiftView.fields.maxPersonsPerReservation')}
                    value={`${shift.maxPersonsPerReservation || ''}`}
                    type="number"
                    required={true}
                    helperText={t('availability.shiftView.helperTexts.maxPersonsPerReservation')}
                    onChange={(val) => {
                        setShift((prev) => {
                            if (!prev) {
                                return prev;
                            }
                            return {
                                ...prev,
                                maxPersonsPerReservation: Number(val) || undefined,
                            };
                        });
                    }}
                />
                <TextField
                    name="slotInterval"
                    label={t('availability.shiftView.fields.slotInterval')}
                    value={getStringFromNumber(shift.slotInterval)}
                    type="number"
                    required={true}
                    helperText={t('availability.shiftView.helperTexts.slotInterval')}
                    onChange={handleSlotIntervalChange}
                    errorMessageKey={'error.message.slotIntervalTooShort'}
                />
                <TextField
                    name="minReservationNotice"
                    label={t('availability.shiftView.fields.minReservationNotice')}
                    value={getStringFromNumber(shift.minReservationNotice)}
                    type="number"
                    helperText={t('availability.shiftView.helperTexts.minReservationNotice')}
                    onChange={handleMinReservationNoticeChange}
                    errorMessageKey={'error.message.leadTimeTooLong'}
                />

                {areaList.length > 0 && (
                    <>
                        <div className="title-areas">
                            <Label
                                type="title"
                                value={t('sectors.title')}
                                variant="h6"
                                align="left"
                                tooltip={t('sectors.info')}
                            />
                        </div>
                        <div className="accordion-area-list">
                            <div>
                                {areaList.map((area: IRestaurantAreaAccordionPreview) => (
                                    <AreaAccordionItem
                                        key={`${shift.id}-${area.id}`}
                                        area={area}
                                        showTablePlan={props.canAccessTablePlan || false}
                                        onDirectlyBookableChange={handleAreaDirectlyBookableChange}
                                        onSliderChange={handleSliderChange}
                                        onAssignAreaChange={handleAssignAreaChange}
                                        onTablePlanChange={handleTablePlanChange}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
                {areaList.length > 0 && props.canAccessAreas && (
                    <>
                        <div className="title-total-onlinecapacity">
                            <Label
                                type="title"
                                value={t('availability.shiftView.title.totalOnlineCapacity')}
                                tooltip={t('availability.shiftView.title.totalOnlineCapacityInfo')}
                                variant="h6"
                                align="left"
                            />
                        </div>
                        <div className="directly-bookable-area-list">
                            <Label type="info" value={t('availability.shiftView.fields.directlyBookableAreasLabel')} />
                            <div className="bookable-list">
                                {areaList.map(
                                    (area: IRestaurantAreaAccordionPreview) =>
                                        area.directlyBookable &&
                                        areaShiftList.findIndex((areaShift) => areaShift.areaId === area.id) !== -1 && (
                                            <div key={`${area.id}_directly_bookable`} className="bookable-list-item">
                                                <Typography>{area.name}</Typography>
                                                <Typography>
                                                    {Math.round((area.capacity * area.capacityPercentage) / 100)}
                                                </Typography>
                                            </div>
                                        ),
                                )}
                            </div>
                        </div>
                        <div className="overall-bookable-list">
                            <Label type="info" value={t('availability.shiftView.fields.allBookable')} />
                            <div className="bookable-list">
                                {virtualAreaPreviewList.map((area: IRestaurantVirtualAreaShiftPreview) => (
                                    <div key={`${area.name}-${area.capacity}`} className="bookable-list-item">
                                        <Typography>{area.name}</Typography>
                                        <Typography>{Math.round(area.capacity)}</Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Typography className="right-content-total">
                            {`${t('availability.shiftView.fields.total')} ${Math.round(previewCapacity)}`}
                        </Typography>
                        <div className="title-preview">
                            <Label
                                type="title"
                                value={t('availability.shiftView.title.preview')}
                                variant="h6"
                                align="left"
                            />
                            <div className="bookable-areas-label">
                                <Label type="info" value={t('availability.shiftView.title.bookableForGuests')} />
                            </div>
                            {groupButtonLabels.length > 0 ? (
                                <ButtonGroup
                                    value={groupButtonLabels.map((g) => g.value)}
                                    options={groupButtonLabels}
                                />
                            ) : (
                                <div className="bookable-areas-label">
                                    <Label
                                        type={'text'}
                                        value={t('availability.shiftView.title.noBookableAreasForGuests')}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    );
};

const validateShift = (shift: IRestaurantShift) => {
    const hasName = Object.keys(shift.name).length > 0;
    if (!hasName) {
        return false;
    }
    if (shift.shiftType === 'recurrent') {
        if (shift.occurrence.length === 0) {
            return false;
        }
    }

    const hasValidTimeRange = DateTimeUtils.hasValidTimeRange(shift.from, shift.to);
    if (!hasValidTimeRange) {
        return false;
    }
    if (!shift.maxPersonsPerReservation) {
        return false;
    }
    if (shift.pacing && shift.pacing < shift.maxPersonsPerReservation) {
        return false;
    }
    if (shift.shiftType === 'soh') {
        if (!DateTimeUtils.hasValidTimeRange(shift.from, shift.to, TIME_FORMAT)) {
            return false;
        }
        if (!shift.dates.length) {
            return false;
        }
    }
    if (!validateBlockedSlots(shift)) {
        return false;
    }

    return ValidationUtils.validateObject(shift, { optionalFields: ['id', 'fromDate', 'toDate'] });
};

const validateBlockedSlots = (shift: IRestaurantShift) =>
    shift.blockedSlots &&
    shift.blockedSlots.every(
        (slot) =>
            (!slot.from && !slot.to) ||
            (slot.from && slot.to && slot.to > slot.from && slot.from >= shift.from && slot.to <= shift.to),
    );

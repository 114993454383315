import {
    ILocalizedError,
    IRecurrentShift,
    IRestaurantArea,
    IRestaurantBaseShift,
    IRestaurantClosingDay,
    IRestaurantShift,
    IRestaurantShiftUpdate,
    ISpecialOpeningHour,
    SERVER_DATE_FORMAT,
    TIME_FORMAT,
} from '@localina/core';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { LocalinaApiContext } from '../../../index';
import { regularShiftOrderByNameEn } from '../../containers/Availability/AvailabilityUtils';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { useAreas } from './restaurantAreas';
import { useRestaurantById } from './restaurants';

const useRestaurantAvailabilityTimes = (restaurantId?: string) => {
    const idOfRestaurant = useRestaurantId(restaurantId);

    const areasQuery = useAreas(idOfRestaurant);

    return useRestaurantById(idOfRestaurant, {
        select: (data) => {
            return data
                ? {
                      shifts: data.configuration.shifts
                          .filter(
                              (shift): shift is IRestaurantBaseShift & IRecurrentShift =>
                                  shift.shiftType === 'recurrent',
                          )
                          .map((shift) => ({
                              ...shift,
                              occurrence: shift.occurrence.sort(),
                          }))
                          .sort((a, b) => sortRecurrentShifts(a, b, areasQuery.data?.areas)),
                      specialOpeningHours: data.configuration.shifts
                          .filter(
                              (shift): shift is IRestaurantBaseShift & ISpecialOpeningHour => shift.shiftType === 'soh',
                          )
                          .sort((a, b) => sortSpecialOpeningHours(a, b, areasQuery.data?.areas)),
                      closingDays: data.configuration.closingDays
                          .map((closingDay) => ({
                              ...closingDay,
                              areas:
                                  areasQuery.data?.areas.filter((area) =>
                                      area.areaClosingDays.includes(closingDay.id),
                                  ) || [],
                          }))
                          .sort(sortClosingDays),
                  }
                : undefined;
        },
    });
};

type TRestaurantClosingDayTransformed = Omit<IRestaurantClosingDay, 'areas'> & {
    areas: (IRestaurantArea & { virtualAreaId: string })[];
};
const sortClosingDays = (cdA: TRestaurantClosingDayTransformed, cdB: TRestaurantClosingDayTransformed) => {
    // sorting by start date
    if (cdA.from !== cdB.from) {
        return cdA.from > cdB.from ? 1 : -1;
    }
    // sorting by longest period
    const periodA = getClosingDayPeriod(cdA);
    const periodB = getClosingDayPeriod(cdB);
    if (periodA.milliseconds !== periodB.milliseconds) {
        return periodA < periodB ? 1 : -1;
    }
    //sorting by areas count
    return cdA.areas.length < cdB.areas.length ? 1 : -1;
};

const sortRecurrentShifts = (
    shiftA: IRestaurantBaseShift & IRecurrentShift,
    shiftB: IRestaurantBaseShift & IRecurrentShift,
    areas?: (IRestaurantArea & { virtualAreaId: string })[],
) => {
    if (shiftA.name.en && shiftB.name.en && shiftA.name.en !== shiftB.name.en) {
        // sort by shift types defined in regularShiftOrderByNameEn const
        return regularShiftOrderByNameEn.indexOf(shiftA.name.en) > regularShiftOrderByNameEn.indexOf(shiftB.name.en)
            ? 1
            : -1;
    }
    for (let i = 0; i < Math.min(shiftA.occurrence.length, shiftB.occurrence.length); i++) {
        // sort from Monday to Sunday
        if (shiftA.occurrence[i] !== shiftB.occurrence[i]) {
            return shiftA.occurrence[i] > shiftB.occurrence[i] ? 1 : -1;
        }
    }
    // shift that starts earlier is displayed first
    if (shiftA.from !== shiftB.from) {
        return shiftA.from > shiftB.from ? 1 : -1;
    }
    // The shift with the most days active is displayed first (Mon-Sun)
    if (shiftA.occurrence.length !== shiftB.occurrence.length) {
        return shiftA.occurrence.length < shiftB.occurrence.length ? 1 : -1;
    }

    // Shift with bigger capacity comes first
    const capacityShiftA = areas ? getShiftAreasCapacity(areas, shiftA) : 0;
    const capacityShiftB = areas ? getShiftAreasCapacity(areas, shiftB) : 0;

    return capacityShiftA > capacityShiftB ? -1 : 1;
};

const getShiftAreasCapacity = (
    areas: (IRestaurantArea & { virtualAreaId: string })[],
    shift: IRestaurantBaseShift & (IRecurrentShift | ISpecialOpeningHour),
) =>
    areas.reduce((cap, area) => {
        const areaShiftIndex = area.areaShifts.findIndex((areaShift) => areaShift.shiftId === shift.id);
        return areaShiftIndex === -1
            ? cap
            : cap + (area.capacity * area.areaShifts[areaShiftIndex].capacityPercentage) / 100;
    }, 0);

const sortSpecialOpeningHours = (
    shiftA: IRestaurantBaseShift & ISpecialOpeningHour,
    shiftB: IRestaurantBaseShift & ISpecialOpeningHour,
    areas?: (IRestaurantArea & { virtualAreaId: string })[],
) => {
    // from oldest to newest
    for (let i = 0; i < Math.min(shiftA.dates.length, shiftB.dates.length); i++) {
        if (shiftA.dates[i] !== shiftB.dates[i]) {
            return shiftA.dates[i] > shiftB.dates[i] ? 1 : -1;
        }
    }
    // longer period comes first
    if (shiftA.dates.length !== shiftB.dates.length) {
        return shiftA.dates.length < shiftB.dates.length ? 1 : -1;
    }

    const periodShiftA = getSpecialOpeningHourTimePeriod(shiftA);
    const periodShiftB = getSpecialOpeningHourTimePeriod(shiftB);

    if (periodShiftA.milliseconds !== periodShiftB.milliseconds) {
        return periodShiftA < periodShiftB ? 1 : -1;
    }

    // Shift with bigger capacity comes first
    const capacityShiftA = areas ? getShiftAreasCapacity(areas, shiftA) : 0;
    const capacityShiftB = areas ? getShiftAreasCapacity(areas, shiftB) : 0;

    return capacityShiftA < capacityShiftB ? 1 : -1;
};

const getSpecialOpeningHourTimePeriod = (soh: IRestaurantBaseShift & ISpecialOpeningHour) => {
    const timeFrom = DateTime.fromFormat(soh.from, TIME_FORMAT);
    const timeTo = DateTime.fromFormat(soh.to, TIME_FORMAT);
    return timeTo.diff(timeFrom);
};

const addHoursAndMinutes = (date: DateTime, time?: string | null) => {
    if (time) {
        const timeObject = DateTime.fromFormat(time, TIME_FORMAT);
        return date.set({ hour: timeObject.hour, minute: timeObject.minute });
    } else {
        return date;
    }
};

const getClosingDayPeriod = (closingDay: TRestaurantClosingDayTransformed) => {
    let from = DateTime.fromFormat(closingDay.from, SERVER_DATE_FORMAT).startOf('day');
    from = addHoursAndMinutes(from, closingDay.fromTime);
    let to = DateTime.fromFormat(closingDay.to, SERVER_DATE_FORMAT).endOf('day');
    to = addHoursAndMinutes(to, closingDay.toTime);

    return to.diff(from);
};

const useCreateRestaurantShift = (
    restaurantId?: string,
    options?: UseMutationOptions<void, ILocalizedError, { shift: IRestaurantShiftUpdate }>,
) => {
    const restaurantIdFromUrl = useRestaurantId();
    const idOfRestaurant = restaurantId || restaurantIdFromUrl;

    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.createRestaurantShift(idOfRestaurant, variables.shift);
        },
        ...options,
    });
};
const useUpdateRestaurantShift = (
    restaurantId?: string,
    options?: UseMutationOptions<void, ILocalizedError, { shift: IRestaurantShiftUpdate }>,
) => {
    const restaurantIdFromUrl = useRestaurantId();
    const idOfRestaurant = restaurantId || restaurantIdFromUrl;

    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.updateRestaurantShift(idOfRestaurant, variables.shift);
        },
        ...options,
    });
};

const useDeleteRestaurantShift = (
    restaurantId?: string,
    options?: UseMutationOptions<void, ILocalizedError, { shift: IRestaurantShift }>,
) => {
    const restaurantIdFromUrl = useRestaurantId();
    const idOfRestaurant = restaurantId || restaurantIdFromUrl;

    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.deleteRestaurantShift(idOfRestaurant, variables.shift);
        },
        ...options,
    });
};

const useCreateRestaurantClosingDay = (
    restaurantId?: string,
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            closingDay: IRestaurantClosingDay;
        }
    >,
) => {
    const idOfRestaurant = useRestaurantId(restaurantId);

    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.createRestaurantClosingDay(idOfRestaurant, variables.closingDay);
        },
        ...options,
    });
};
const useUpdateRestaurantClosingDay = (
    restaurantId?: string,
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            closingDay: IRestaurantClosingDay;
        }
    >,
) => {
    const idOfRestaurant = useRestaurantId(restaurantId);

    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.updateRestaurantClosingDay(idOfRestaurant, variables.closingDay);
        },
        ...options,
    });
};
const useDeleteRestaurantClosingDay = (
    restaurantId?: string,
    options?: UseMutationOptions<void, ILocalizedError, { closingDayId: string }>,
) => {
    const idOfRestaurant = useRestaurantId(restaurantId);

    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.deleteRestaurantClosingDay(idOfRestaurant, variables.closingDayId);
        },
        ...options,
    });
};

export {
    useCreateRestaurantShift,
    useUpdateRestaurantShift,
    useDeleteRestaurantShift,
    useCreateRestaurantClosingDay,
    useUpdateRestaurantClosingDay,
    useDeleteRestaurantClosingDay,
    useRestaurantAvailabilityTimes,
};

export type { TRestaurantClosingDayTransformed };

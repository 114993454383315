import {
    createEmptyClosingDay,
    IRestaurantShiftUpdate,
    ISpeedDialActionProps,
    List,
    MAX_ALLOWED_SHIFTS,
    RestaurantSubscription,
    SpeedDial,
    Spinner,
} from '@localina/core';
import { NightLifeIcon, RemoveMinusIcon, RestaurantMenuIcon } from '@localina/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountSubscription, useHaveAccountFeatures } from '../../api/queries/account';
import {
    TRestaurantClosingDayTransformed,
    useCreateRestaurantShift,
    useDeleteRestaurantShift,
    useRestaurantAvailabilityTimes,
    useUpdateRestaurantShift,
} from '../../api/queries/restaurantShifts';
import {
    ClosingDayListItem,
    ClosingDayView,
    ClosingDayViewType,
    IClosingDayViewProps,
    ShiftListItem,
    ShiftView,
    SpecialOpeningHourListItem,
} from '../../components';

interface IAvailabilityContentProps {
    restaurantId?: string;
}

const AvailabilityContent = (props: IAvailabilityContentProps) => {
    const { t } = useTranslation();

    const [selectedShiftId, setSelectedShiftId] = useState('');
    const [mode, setMode] = useState<'soh' | 'recurrent'>('recurrent');
    const [openShiftView, setOpenShiftView] = useState(false);
    const [closingDayView, setClosingDayView] = React.useState<IClosingDayViewProps | undefined>(undefined);

    const availabilityTimesQuery = useRestaurantAvailabilityTimes(props.restaurantId);

    const createRestaurantShiftMutation = useCreateRestaurantShift(props.restaurantId);
    const updateRestaurantShiftMutation = useUpdateRestaurantShift(props.restaurantId);
    const deleteRestaurantShiftMutation = useDeleteRestaurantShift(props.restaurantId);

    const [canAccessAreas, canAccessTablePlan] = useHaveAccountFeatures(['areas', 'tablePlans']);
    const subscription = useGetAccountSubscription();

    const selectedShift = availabilityTimesQuery.data
        ? [...availabilityTimesQuery.data.shifts, ...availabilityTimesQuery.data.specialOpeningHours].find(
              (s) => s.id === selectedShiftId,
          )
        : undefined;

    const onSuccess = async () => {
        handleClosingDayClose();
        handleShiftViewClose();
        await availabilityTimesQuery.refetch();
    };

    const handleCreateClosingDay = () => {
        setClosingDayView({
            type: ClosingDayViewType.CREATE,
            closingDay: createEmptyClosingDay(),
        });
    };
    const handleEditClosingDay = (closingDay: TRestaurantClosingDayTransformed) => {
        setClosingDayView({
            type: ClosingDayViewType.EDIT,
            closingDay,
        });
    };

    const handleShiftChange = (value: IRestaurantShiftUpdate) => {
        if (!selectedShift) {
            createRestaurantShiftMutation.mutate({ shift: value }, { onSuccess });
        } else {
            updateRestaurantShiftMutation.mutate({ shift: value }, { onSuccess });
        }
    };

    const handleClosingDayClose = () => {
        setClosingDayView(undefined);
    };
    const handleShiftViewClose = () => {
        setSelectedShiftId('');
        setOpenShiftView(false);
    };

    const handleShiftDelete = () => {
        if (selectedShift) {
            deleteRestaurantShiftMutation.mutate({ shift: selectedShift }, { onSuccess });
        }
    };

    const createActions: ISpeedDialActionProps[] = [
        {
            label: t('availability.actions.createShift'),
            icon: <RestaurantMenuIcon />,
            disabled:
                subscription === RestaurantSubscription.FREE &&
                availabilityTimesQuery.data?.shifts.length === MAX_ALLOWED_SHIFTS,
            onClick: () => {
                setOpenShiftView(true);
                setMode('recurrent');
            },
        },
        {
            label: t('availability.actions.createSpecialOpeningHour'),
            icon: <NightLifeIcon />,
            onClick: () => {
                setOpenShiftView(true);
                setMode('soh');
            },
        },
        {
            label: t('availability.actions.createClosingDay'),
            icon: <RemoveMinusIcon />,
            onClick: handleCreateClosingDay,
        },
    ];

    const isLoading =
        createRestaurantShiftMutation.isLoading ||
        deleteRestaurantShiftMutation.isLoading ||
        updateRestaurantShiftMutation.isLoading;

    const shiftMutationError =
        createRestaurantShiftMutation.error ||
        updateRestaurantShiftMutation.error ||
        deleteRestaurantShiftMutation.error;

    return (
        <>
            <List>
                {availabilityTimesQuery.data?.shifts.map((shift, i) => (
                    <ShiftListItem
                        key={i}
                        shift={shift}
                        onClick={() => {
                            setSelectedShiftId(shift.id);
                            setMode('recurrent');
                            setOpenShiftView(true);
                        }}
                    />
                ))}
                {availabilityTimesQuery.data?.specialOpeningHours.map((specialOpeningHour, i) => (
                    <SpecialOpeningHourListItem
                        key={i}
                        specialOpeningHour={specialOpeningHour}
                        onClick={() => {
                            setSelectedShiftId(specialOpeningHour.id);
                            setMode('soh');
                            setOpenShiftView(true);
                        }}
                    />
                ))}
                {availabilityTimesQuery.data?.closingDays.map((closingDay, i) => (
                    <ClosingDayListItem key={i} closingDay={closingDay} onClick={handleEditClosingDay} />
                ))}
            </List>
            <ShiftView
                canAccessAreas={canAccessAreas}
                canAccessTablePlan={canAccessTablePlan}
                type={mode}
                restaurantId={props.restaurantId}
                hasError={Boolean(shiftMutationError)}
                open={openShiftView}
                shift={selectedShift}
                error={shiftMutationError}
                onClose={handleShiftViewClose}
                onSave={handleShiftChange}
                onDelete={handleShiftDelete}
            />
            <ClosingDayView
                {...closingDayView}
                restaurantId={props.restaurantId}
                canAccessAreas={canAccessAreas}
                onClose={handleClosingDayClose}
                onSuccess={onSuccess}
            />
            <SpeedDial label="create" actions={createActions} fixed />
            {isLoading && <Spinner />}
        </>
    );
};

export default AvailabilityContent;

import {
    ILocalizedError,
    IMenuConfiguration,
    IMenusList,
    IUpdateMenuContentVariables,
    SERVER_DATE_FORMAT,
} from '@localina/core';
import { useInfiniteQuery, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';
import { DateTime } from 'luxon';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';
import { getSlicedListPageParam } from './utils';
import { useRestaurant } from './restaurants';

const MENU_DAYS_COUNT = 30;

const useRestaurantMenus = (
    options?: UseInfiniteQueryOptions<
        IMenusList,
        ILocalizedError,
        IMenusList,
        IMenusList,
        ReturnType<typeof queryKeys.restaurants.single.menus.filtered>
    >,
) => {
    const restaurantId = useRestaurantId();

    const dateFrom = DateTime.now().toFormat(SERVER_DATE_FORMAT);
    const dateTo = DateTime.now().plus({ day: MENU_DAYS_COUNT }).toFormat(SERVER_DATE_FORMAT);

    return useInfiniteQuery({
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getRestaurantMenu(restaurantId, dateFrom, dateTo);
        },
        queryKey: queryKeys.restaurants.single.menus.filtered(restaurantId, dateFrom, dateTo),
        ...options,
        getNextPageParam: getSlicedListPageParam.next,
        getPreviousPageParam: getSlicedListPageParam.prev,
        select: (data) => {
            return {
                ...data,
                pages: data.pages.map((page) => {
                    return {
                        ...page,
                        items: page.items.filter((menu) => {
                            let parsed;
                            try {
                                parsed = JSON.parse(menu.content);
                            } catch (e) {
                                return false;
                            }
                            return menu.content && parsed;
                        }),
                    };
                }),
            };
        },
    });
};

const useUpdateMenuContent = (options?: UseMutationOptions<void, ILocalizedError, IUpdateMenuContentVariables>) => {
    const restaurantId = useRestaurantId();
    const useMenusQuery = useRestaurantMenus();

    return useMutation({
        mutationFn: (variables) => LocalinaApiContext.serviceApi.updateRestaurantMenuContent(restaurantId, variables),
        ...options,
        onSuccess: async () => {
            return useMenusQuery.refetch();
        },
    });
};

const useUpdateMenuConfigurationMutation = (
    options?: UseMutationOptions<void, ILocalizedError, IMenuConfiguration>,
) => {
    const restaurantQuery = useRestaurant();

    return useMutation({
        mutationFn: (variables) =>
            LocalinaApiContext.serviceApi.updateRestaurantMenuConfiguration(restaurantQuery.data?.id || '', variables),
        onSuccess: () => {
            return restaurantQuery.refetch();
        },
        ...options,
    });
};

export { MENU_DAYS_COUNT, useRestaurantMenus, useUpdateMenuConfigurationMutation, useUpdateMenuContent };

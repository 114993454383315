export enum Path {
    AUTH = '/auth',
    LOGIN = '/login',
    REGISTER = '/register',
    VERIFY_EMAIL = '/verify-email',
    RESET_PASSWORD = '/reset-password',

    REVIEW_PENDING_RESERVATIONS = '/reservations/review',

    ACCOUNT_RESTAURANTS = '/account/restaurants',
    ACCOUNT_USERS = '/account/users',

    SUPPORT_ACCOUNTS = '/support/accounts',
    SUPPORT_STATISTICS = '/support/statistics',
    SUPPORT_USERS = '/support/users',
    SUPPORT_USER = '/support/users/:userId',

    RESTAURANT = '/:restaurantId',
    RESTAURANT_DASHBOARD = '/:restaurantId/dashboard',
    RESTAURANT_RESERVATIONS = '/:restaurantId/reservations',
    RESTAURANT_GUESTS = '/:restaurantId/guests',
    RESTAURANT_GUEST = '/:restaurantId/guests/:guestId',
    RESTAURANT_GUEST_RESERVATION = '/:restaurantId/guests/:guestId/reservation/:reservationId',
    RESTAURANT_TABLE_PLAN_DETAILS = '/:restaurantId/tablePlan/:tablePlanId',
    RESTAURANT_TABLE_PLAN_ASSIGN_RESERVATIONS = '/:restaurantId/reservations/assign-reservations',

    RESTAURANT_SETTINGS_REPORTING = '/:restaurantId/settings/reporting',
    RESTAURANT_SETTINGS_CAPACITY = '/:restaurantId/settings/capacity',
    RESTAURANT_SETTINGS_AREAS = '/:restaurantId/settings/areas',
    RESTAURANT_SETTINGS_AVAILABILITY = '/:restaurantId/settings/availability',
    RESTAURANT_SETTINGS_TABLE_PLAN = '/:restaurantId/settings/tablePlan',
    RESTAURANT_SETTINGS_INTEGRATIONS = '/:restaurantId/settings/integrations',
    RESTAURANT_SETTINGS_INTEGRATION = '/:restaurantId/settings/integrations/:integrationId',
    RESTAURANT_SETTINGS_INTEGRATION_MENU = '/:restaurantId/settings/integrations/menu',
    RESTAURANT_SETTINGS_MESSAGES = '/:restaurantId/settings/messages',
    RESTAURANT_SETTINGS_MESSAGE = '/:restaurantId/settings/messages/:messageId',
    RESTAURANT_SETTINGS_STAFF = '/:restaurantId/settings/staff',
}

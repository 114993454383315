var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, Tabs } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import root from 'react-shadow/emotion';
import { DAY_SHORT_DATE_FORMAT, SERVER_DATE_FORMAT } from '../../config';
import { Label } from '../Label';
import { RichTextEditor } from '../RichTextEditor';
// Max number of dates to be displayed.
// It will display only dates in the next n days
var PUBLIC_MENU_DAYS_COUNT = 7;
export var RestaurantPublicMenu = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var tabs = useMemo(function () {
        var _a;
        var lastDateToShow = DateTime.now().startOf('day').plus({ day: PUBLIC_MENU_DAYS_COUNT });
        return (((_a = props.menuConfiguration) === null || _a === void 0 ? void 0 : _a.menu) || [])
            .filter(function (menuObject) {
            return lastDateToShow.diff(DateTime.fromFormat(menuObject.date, SERVER_DATE_FORMAT)).milliseconds > 0;
        })
            .map(function (menuObject) { return ({
            label: DateTime.fromFormat(menuObject.date, SERVER_DATE_FORMAT).toFormat(DAY_SHORT_DATE_FORMAT),
            value: menuObject.date,
        }); });
    }, [(_a = props.menuConfiguration) === null || _a === void 0 ? void 0 : _a.menu]);
    var _j = useState(null), activeTab = _j[0], setActiveTab = _j[1];
    var onTabChange = function (event, value) {
        setActiveTab(value);
    };
    var activeContent = useMemo(function () { var _a, _b; return (activeTab ? (_b = (_a = props.menuConfiguration) === null || _a === void 0 ? void 0 : _a.menu.find(function (_a) {
        var date = _a.date;
        return date === activeTab;
    })) === null || _b === void 0 ? void 0 : _b.content : undefined); }, [activeTab, (_b = props.menuConfiguration) === null || _b === void 0 ? void 0 : _b.menu]);
    useEffect(function () {
        var _a;
        if (tabs.length) {
            setActiveTab((_a = tabs[0]) === null || _a === void 0 ? void 0 : _a.value);
        }
    }, [tabs]);
    return (_jsx("div", __assign({ className: 'localina-restaurant-public-menu', style: ((_c = props.menuConfiguration) === null || _c === void 0 ? void 0 : _c.backgroundColor)
            ? { backgroundColor: "".concat(String(props.menuConfiguration.backgroundColor)) }
            : undefined }, { children: _jsxs(root.div, __assign({ className: 'rootdiv' }, { children: [((_d = props.menuConfiguration) === null || _d === void 0 ? void 0 : _d.cssUrl) && _jsx("link", { rel: "stylesheet", href: props.menuConfiguration.cssUrl }), Boolean(activeTab) && (_jsx(Tabs, __assign({ className: 'localina-tabs', value: activeTab, onChange: onTabChange, variant: "scrollable", scrollButtons: "auto", sx: {
                        '.MuiTabs-flexContainer::before,.MuiTabs-flexContainer::after': {
                            //used to keep tab items centered (because justifyContent: center couldn't work)
                            content: "\"\"",
                            width: '100%',
                        },
                        '.MuiTypography-root': {
                            textTransform: 'none',
                        },
                        '.MuiTabs-indicator': {
                            backgroundColor: ((_e = props.menuConfiguration) === null || _e === void 0 ? void 0 : _e.activeTabTextColor)
                                ? "".concat(String(props.menuConfiguration.activeTabTextColor), " !important")
                                : undefined,
                        },
                        '& .MuiTab-root': {
                            color: ((_f = props.menuConfiguration) === null || _f === void 0 ? void 0 : _f.defaultTabTextColor)
                                ? "".concat(String(props.menuConfiguration.defaultTabTextColor), " !important")
                                : undefined,
                            '.MuiTypography-root': {
                                fontFamily: ((_g = props.menuConfiguration) === null || _g === void 0 ? void 0 : _g.tabTextFontFamily)
                                    ? "".concat(String(props.menuConfiguration.tabTextFontFamily), " !important")
                                    : undefined,
                            },
                            '&.Mui-selected': {
                                color: ((_h = props.menuConfiguration) === null || _h === void 0 ? void 0 : _h.activeTabTextColor)
                                    ? "".concat(String(props.menuConfiguration.activeTabTextColor), " !important")
                                    : undefined,
                            },
                        },
                    } }, { children: tabs.map(function (tab, index) { return (_jsx(Tab, { className: 'localina-tab', label: _jsx(Label, { type: "text", value: tab.label }), value: tab.value, onClick: function () {
                            setActiveTab(tab.value);
                        } }, index)); }) }))), _jsx(RichTextEditor, { readonly: true, content: activeContent ? JSON.parse(activeContent) : undefined })] })) })));
};

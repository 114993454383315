var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Color from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import Toolbar from './Toolbar';
export var RichTextEditor = function (props) {
    var editor = useEditor({
        editable: !props.readonly,
        extensions: [
            StarterKit.configure({
                heading: {
                    levels: [1, 2, 3],
                },
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Underline,
            TextStyle,
            FontFamily,
            Color,
        ],
        content: props.content,
        onUpdate: function (_a) {
            var editor = _a.editor;
            if (props.setContentNotSaved) {
                props.setContentNotSaved(!props.content
                    ? !editor.isEmpty
                    : JSON.stringify(editor.getJSON()) !== JSON.stringify(props.content));
            }
        },
    });
    var handleSave = function () {
        if (props.onSave && editor) {
            props.onSave(editor.isEmpty ? null : editor.getJSON());
        }
    };
    useEffect(function () {
        if (props.content) {
            editor === null || editor === void 0 ? void 0 : editor.commands.setContent(props.content);
        }
        else {
            editor === null || editor === void 0 ? void 0 : editor.commands.clearContent();
        }
        if (props.setContentNotSaved) {
            props.setContentNotSaved(false);
        }
    }, [props.content, props.contentRelatedString]);
    return (_jsxs("div", __assign({ className: 'localina-rich-text-editor-container' }, { children: [!props.readonly && _jsx(Toolbar, { editor: editor, onSave: handleSave }), _jsx(EditorContent, { editor: editor, className: 'editor-content' })] })));
};
